const HASURA_GRAPHQL_ENGINE_HOSTNAME = process.env.REACT_APP_API_HOST;
const FRONTEND = process.env.REACT_APP_FRONTEND;

const scheme = proto =>
  window.location.protocol === 'https:' ? `${proto}s` : proto;

export const GRAPHQL_URL = `${scheme(
  'http',
)}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;

export const FILES_URL = `https://${process.env.REACT_APP_FILES}/`;
export const UPLOAD_API = `${scheme('http')}://${process.env.REACT_APP_FILES_UPLOAD
  }/`;

export const REALTIME_GRAPHQL_URL = `${scheme(
  'ws',
)}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
export const authDomain = process.env.REACT_APP_DOMAIN;
export const authClientId = process.env.REACT_APP_CLIENT_ID;
export const callbackUrl = `${scheme('http')}://${FRONTEND}/callback`;
export const AUTH0_API_IDENTIFIER = `https://${authDomain}/api/v2/`;

export const AUTH_CONFIG = {
  domain: authDomain,
  clientId: authClientId,
  callbackUrl,
  audience: AUTH0_API_IDENTIFIER,
};
