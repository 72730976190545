import React, { useContext } from 'react';
import { Auth0Context } from '../../hooks/AuthContext';

import FormGroup from '@material-ui/core/FormGroup';
import InputBase from '@material-ui/core/InputBase';
import GraphCheckboxes from './graphCheckboxes';
import Checkboxes from './checkboxes';
import Range from './range';
import { filter } from '../../store/selectors';


const FilterType = React.memo(props  => {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;
  const {type, classes, options, field, store, title} = props;
  const filteredOptions = filter(user.role, options, field, type);
  switch (type) {
    case 'text':
      return <InputBase
        id={field}
        className={classes.inputText}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'Search' }}
        type={type}
        /*
        onChange={e => handleChange(e)}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            handleChange(e);
          }
        }}
        */
      /> 
    case 'filter':
      return options.renderAs === 'graphfilter' && <div className={classes.formGroupFixed}>
          <FormGroup>
            <GraphCheckboxes
              className={classes.graphSelect}
              field={field}
              query={options.query}
              onBlur={() => true}
              store={store}
              title={title}
              type={type}
            />
          </FormGroup>
        </div>
    case 'select':
    case 'string':
    case 'filteredSelect':
    case 'checkbox': 
    case 'suppliers':
      return <div className={classes.formGroupScroller}>
            <FormGroup>
              <Checkboxes
                className={classes.checkbox}
                values={filteredOptions}
                field={field}
                store={store}
                title={title}
                type={type}
              />
            </FormGroup>
        </div>
    case "graphselect":
      return <div className={classes.formGroupFixed}>
          <FormGroup>
            <GraphCheckboxes
              className={classes.graphSelect}
              field={field}
              query={options.query}
              onBlur={() => true}
              store={store}
              title={title}
              type={type}
            />
          </FormGroup>
        </div>
    case 'date': {
      const year = new Date().getFullYear();
    
      return <div className={classes.formGroupScroller}>
          <FormGroup>
            <Range
              className={classes.checkbox}
              values={options}
              field={field}
              store={store}
              title={title}
              min={1980}
              max={year}
              type={type}
            />
          </FormGroup>
      </div>
    }
    default:
      return null
  }
});


export default FilterType;
