import React from 'react';
import PropTypes from 'prop-types';

function Concat({
  first,
  second,
  divider,
  empty,
  className,
  prefix,
  postfix,
  label,
}) {
  const labelSpan = label ? `${label}` : null;
  const prefixSpan = prefix ? `${prefix}` : null;
  const postfixSpan = postfix ? `${postfix}` : null;
  const dividerSpan = divider ? `${divider}` : null;
  if (first && second)
    return (
      <span className={className}>
        {labelSpan} {prefixSpan} {first} {postfixSpan} {dividerSpan} {second}
      </span>
    );
  if (second)
    return (
      <span className={className}>
        {labelSpan} {postfixSpan} {second}
      </span>
    );
  if (first)
    return (
      <span className={className}>
        {labelSpan} {prefixSpan} {first} {postfixSpan}
      </span>
    );
  if (empty) return <span className={className}>{empty}</span>;
  return null;
}

Concat.defaultProps = {
  first: null,
  second: null,
  divider: null,
  empty: null,
  prefix: null,
  postfix: null,
  label: null,
};

Concat.propTypes = {
  first: PropTypes.string,
  second: PropTypes.string,
  divider: PropTypes.string,
  empty: PropTypes.string,
  className: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  label: PropTypes.string,
};

export default Concat;
