import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TagManager from 'react-gtm-module';

// Layout
import layout from '../../config/product-layout';
import { getNonEmptyFields, getProductImpressions } from '../../scripts/utils';
import Can from '../Can';
import { FILES_URL } from '../../config/constants';
import Item from './item';

const display = layout.product;

const styles = () => ({
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  smalltable: {
    width: 'auto',
    marginBottom: 50,
    '& td, & th': {
      borderColor: '#A5D8DA',
    },
  },
  headCell: {
    whiteSpace: 'nowrap',
    width: 350,
    maxWidth: 350,
    minWidth: 350,
  },
  valueCell: {
    minWidth: 200,
    maxWidth: 400,
  },
  image: {
    display: 'block',
    maxWidth: '200px',
    maxHeight: '250px',
    margin: '20px 0 20px',
  },
});

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(165, 216, 218, 0.15)',
    },
  },
}))(TableRow);

function ProductSummary(props) {
  const { classes, products } = props;
  const nonEmptyFields = getNonEmptyFields(display.fields, products);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'productDetails',
        ecommerce: {
          detail: {
            actionField: { list: 'Product Details' }, // 'detail' actions have an optional list property.
            products: getProductImpressions(products, 'Product Details', 1),
          },
        },
      },
    });
    return () => {};
  }, [products]);

  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.smalltable} size="small">
        <TableBody>
          <StyledTableRow key="image">
            {products.map(
              product =>
                product.file &&
                product.file.path && (
                  <TableCell
                    colSpan="2"
                    align="left"
                    key={`imge-${product.id}`}
                  >
                    <img
                      className={classes.image}
                      src={`${FILES_URL}${product.file.path}`}
                      alt={product.brand_name}
                    />
                  </TableCell>
                ),
            )}
          </StyledTableRow>
          <Can
            perform="product:edit"
            yes={() => (
              <StyledTableRow key="id">
                <TableCell className={classes.headCell}>ID:</TableCell>
                {products.map(product => (
                  <TableCell
                    className={classes.valueCell}
                    key={`summary-${product.id}`}
                  >
                    <Link
                      className={classes.editButton}
                      to={`/update/product/${product.id}`}
                    >
                      <IconButton
                        size="small"
                        aria-label="Details"
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Link>
                    {product.id}
                  </TableCell>
                ))}
              </StyledTableRow>
            )}
          />

          {Object.keys(nonEmptyFields).map(name => {
            if (nonEmptyFields[name].type === 'subform') {
              return null;
            }
            if (nonEmptyFields[name].type === 'files') {
              return null;
            }
            return (
              <StyledTableRow key={nonEmptyFields[name].label}>
                <TableCell
                  className={classes.headCell}
                  key={nonEmptyFields[name].label}
                >
                  {nonEmptyFields[name].label}
                </TableCell>
                {products.map(product => (
                  <TableCell
                    className={classes.valueCell}
                    key={`${product.id}-${nonEmptyFields[name].label}`}
                  >
                    <Item
                      value={product[name]}
                      options={display.fields[name].options}
                    />
                  </TableCell>
                ))}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

ProductSummary.defaultProps = {};

ProductSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProductSummary);
