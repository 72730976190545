import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: red;
  white-space: pre;
`;

const Error = ({ message }) => <Wrapper>{message}</Wrapper>;

Error.defaultProps = {};

Error.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Error;
