/**
 *
 * ProductsPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import IconButton from '@material-ui/core/IconButton';

import LinkIcon from '@material-ui/icons/AttachFile';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import { isFavorite } from '../../store/selectors';

import { FILES_URL } from '../../config/constants';

// Utils
const styles = () => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
  },
  actions: {
    alignItems: 'flex-end',
    margin: 'auto 0 0 0',
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '250px',
    margin: '20px auto 0',
  },
});

const StyledCardContent = styled(CardContent)`
  text-align: center;
`;

const Item = props => {
  const {
    item,
    classes,
    onFavoriteToggle,
    isFavoriteItem,
    multiple,
    onChange,
  } = props;

  return (
    <>
      <Card className={classes.card} elevation={2}>
        <StyledCardContent>
          {item.name}
          <br />
          {item.description}
          <br />
          <img
            className={classes.image}
            src={`${FILES_URL}${item.path}`}
            alt={item.name}
          />
        </StyledCardContent>
        <CardActions className={classes.actions}>
          <IconButton
            aria-label="Favourite"
            color={isFavoriteItem ? 'primary' : 'secondary'}
            onClick={() =>
              multiple || onChange === undefined
                ? onFavoriteToggle()
                : onChange(item)
            }
          >
            <LinkIcon />
          </IconButton>
        </CardActions>
      </Card>
    </>
  );
};

Item.defaultProps = {
  item: {},
  multiple: false,
  onChange: undefined,
};

Item.propTypes = {
  onChange: PropTypes.func,
  classes: PropTypes.object.isRequired,
  onFavoriteToggle: PropTypes.func.isRequired,
  isFavoriteItem: PropTypes.bool.isRequired,
  item: PropTypes.object,
  multiple: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  return {
    isFavoriteItem: isFavorite(state, 'files', props.item),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onFavoritesAdded: () =>
      dispatch({
        type: actionTypes.ADD_FAVORITE,
        store: 'files',
        item: props.item,
      }),
    onFavoriteRemoved: () =>
      dispatch({
        type: actionTypes.REMOVE_FAVORITE,
        store: 'files',
        item: props.item,
      }),
    onFavoriteToggle: () =>
      dispatch({
        type: actionTypes.TOGGLE_FAVORITE,
        store: 'files',
        item: props.item,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Item));
