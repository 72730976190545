/**
 *
 * Ingedients liosting
 *
 */

import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components/macro';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';

import TagManager from 'react-gtm-module';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { Auth0Context } from '../../hooks/AuthContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import Error from '../../components/Error';
import Concat from '../../components/Concat';

import Price from '../../components/Price';
import { GET_INGREDIENTS } from '../../queries';
import Can from '../../components/Can';
import { getIngredientImpressions, getFilter } from '../../scripts/utils';

import VisibilitySensor from '../../components/visibilitySensor';

import { getSearch, getValues } from '../../store/selectors';

import layout from '../../config/ingredient-layout.json';
import DemoDialog from '../../components/DemoDialog';

const display = layout.ingredient;

// Utils
const MAXITEMS = 24;
const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
  },
  actions: {
    alignItems: 'flex-end',
    margin: 'auto 0 0 0',
  },
  buttons: {
    margin: '20px -5px',
  },
  button: {
    margin: 5,
  },
});

const StyledConcat = styled(Concat)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const onImpression = (item, i) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'ingredientImpressions',
      ecommerce: {
        impressions: getIngredientImpressions([item], 'Ingredient List', i),
      },
    },
  });
};

const Listing = props => {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;

  const { classes, searchValue, values, limit } = props;
  const [filter, setFilter] = useState();

  useEffect(() => {
    const handleFilter = debounce(() => {
      const f = getFilter('ingredient', searchValue, values, display.fields);
      setFilter(f);
    }, 500);

    handleFilter();
  }, [searchValue, values]);

  // const [offset, setOffset] = useState(propsOffset);
  const { loading, error, data, fetchMore } = useQuery(
    GET_INGREDIENTS({
      with_price_active:
        (user && user.access && user.access.ingredient_price_active) || false,
    }),
    {
      variables: {
        offset: 0,
        limit,
        filter,
      },
    },
  );

  const onLoadMore = offset => {
    const variables = {
      limit,
      offset,
    };
    if (fetchMore === undefined) return;
    fetchMore({
      // query,
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        const ingredients =
          variables.offset === 0
            ? fetchMoreResult.ingredients
            : previousResult.ingredients.concat(fetchMoreResult.ingredients);

        return {
          allItems: fetchMoreResult.allItems,
          ingredients,
        };
      },
    });
  };

  const handleMore = o => {
    onLoadMore(o);
  };

  if (loading) return <LoadingIndicator />;
  if (error) return <Error message={error.message} />;

  const { ingredients: items, allItems } = data;
  const count = items ? items.length : 0;
  const max = allItems ? allItems.aggregate.count : 0;

  return (
    <>
      <h4>
        {max > 0
          ? `Show ${count} of ${max} ingredients`
          : 'No ingredients found'}
      </h4>
      <Grid container spacing={2}>
        {items &&
          items.length !== 0 &&
          items.map((item, i) => (
            <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
              <VisibilitySensor partialVisibility once>
                {({ isVisible }) => {
                  if (isVisible) {
                    onImpression(item, i + 1);
                  }
                  return (
                    <Card className={classes.card} elevation={2}>
                      <CardContent>
                      <Can
                          perform="ingredient.field.name"
                          yes={() => (
                            <StyledConcat first={item.name} empty="-" />
                          )}
                        />
                      <Can
                          perform="ingredient.field.type"
                          yes={() => (
                            <StyledConcat first={item.type} empty="-" />
                          )}
                        />
                      <Can
                          perform="ingredient.field.cas"
                          yes={() => (
                            <StyledConcat
                            label="CAS #"
                            first={item.cas}
                            empty="-"
                          />  
                          )}
                        />
                      <Can
                          perform="ingredient.field.ec_number"
                          yes={() => (
                            <StyledConcat
                            label="EC #"
                            first={item.ec_number}
                            empty="-"
                          />  
                          )}
                        />
                      <Can
                          perform="ingredient.field.format"
                          yes={() => (
                            <StyledConcat first={item.format} empty="-" />

                          )}
                        />
                        <Can
                          perform="ingredient.field.price_active"
                          yes={() => (
                            <>
                              <br />
                              {item.price_active ? (
                                <>
                                  <Price
                                    value={item.price_active}
                                    empty="-"
                                    decimals={0}
                                  />
                                  /ton
                                </>
                              ) : (
                                '-'
                              )}
                            </>
                          )}
                        />
                      </CardContent>
                      <CardActions className={classes.actions}>
                        <Can
                          perform="ingredient:zoom"
                          yes={() => (
                            <Link to={`/ingredients/details/${item.id}`}>
                              <IconButton aria-label="Search" color="primary">
                                <SearchIcon />
                              </IconButton>
                            </Link>
                          )}
                        />

                        <Can
                          perform="ingredient:detail"
                          yes={() => (
                            <Link to={`/ingredient/${item.id}`}>
                              <IconButton aria-label="Details" color="primary">
                                <InfoIcon />
                              </IconButton>
                            </Link>
                          )}
                          no={() => (
                            <DemoDialog to="/ingredient/detail-demo" icon={ <InfoIcon />}>
                             
                            </DemoDialog>
                          )}
                        />
                      </CardActions>
                    </Card>
                  );
                }}
              </VisibilitySensor>
            </Grid>
          ))}
      </Grid>
      <div className={classes.buttons}>
        {count < max && (
          <Can
          perform="ingredient:more"
          yes={() => (
              <Button
                variant="contained"
                type="button"
                color="primary"
                className={classes.button}
                onClick={() => handleMore(count)}
                aria-label="Load more"
              >
                Load more
            </Button>
            )}
          />


         
        )}
      </div>
    </>
  );
};

Listing.defaultProps = {
  limit: MAXITEMS,
  searchValue: '',
  values: [],
};

Listing.propTypes = {
  classes: PropTypes.object.isRequired,
  limit: PropTypes.number,
  values: PropTypes.array,
  searchValue: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    searchValue: getSearch(state, 'ingredients'),
    values: getValues(state, 'ingredients'),
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Listing));
