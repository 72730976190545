import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Query } from '@apollo/client/react/components';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableContainer } from '@material-ui/core';
import { connect } from 'react-redux';
import { Auth0Context } from '../../hooks/AuthContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import Error from '../../components/Error';
import { GET_PRODUCT } from '../../queries';
import Ingredients from '../../components/ProductSections/ingredientsCompare';
// import Materials from '../../components/ProductSections/materials';
import Summary from '../../components/ProductSections/summaryCompare';
import Totals from '../../components/ProductSections/totalsCompare';
import { getFavorites } from '../../store/selectors';
import history from '../../scripts/history';
import { FILES_URL } from '../../config/constants';
import { mapOrder } from '../../scripts/utils';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 50,
  },
  imageWrapper: {
    height: 140,
  },
  image: {
    display: 'block',
    maxWidth: '100px',
    maxHeight: '100px',
    margin: '10px 0 10px',
  },
  tableContainer: {
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 200px)',
    overflowX: 'auto',
  },
  compareTable: {
    marginBottom: 50,
    '& td, & th': {
      borderColor: '#A5D8DA',
    },
  },
  headCell: {
    width: 'auto',
    minWidth: 350,
    whiteSpace: 'nowrap',
  },
  valueCell: {
    minWidth: 250,
    maxWidth: 500,
  },
  headerCell: {
    paddingTop: 50,
    fontWeight: 'bold',
  },
});

function ProductDetailsPage(props) {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;

  const { classes, favorites } = props;

  const ids = favorites.map(p => p.id);
  // ids.push(1673);
  // ids.push(1810);
  // const ids = [1800, 1810];

  if (!ids || ids.length <= 0) {
    history.replace(`/products`);
    return <Error message="Error loading product.." />;
  }

  return (
    <Query
      query={GET_PRODUCT({
        with_price_active:
          (user && user.access && user.access.product_price_active) || false,
        with_percentage:
          (user && user.access && user.access.percentage) || false,
      })}
      variables={{
        ids,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingIndicator />;
        }

        if (error) {
          return <Error message={error.message} />;
        }

        if (!data.product || data.product.length <= 0) {
          history.replace(`/products`);
          return <Error message="Error loading product.." />;
        }
        const products = mapOrder(data.product, ids, 'id');
        // const products = data.product;

        return (
          <>
            <Helmet>
              <title>Product Compare Page</title>
              <meta name="description" content="" />
            </Helmet>

            <Paper className={classes.container} elevation={2}>

              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.compareTable}
                  size="small"
                >
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell className={classes.headCell} />
                      {products.map(product => {
                        return (
                          <TableCell
                            className={classes.valueCell}
                            key={`${product.id}`}
                            style={{
                              width: `${100 / products.length}%`,
                            }}
                          >
                            <div className={classes.imageWrapper}>
                              <div>{product.id}</div>
                              {product.file && product.file.path && (
                                <img
                                  className={classes.image}
                                  src={`${FILES_URL}${product.file.path}`}
                                  alt={product.brand_name}
                                />
                              )}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <Summary products={products} />
                    <Totals products={products} />
                    <Ingredients products={products} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        );
      }}
    </Query>
  );
}

ProductDetailsPage.defaultProps = {};

ProductDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    favorites: getFavorites(state, 'products'),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ProductDetailsPage));
