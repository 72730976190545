/**
 *
 * ProductsPage
 *
 */

import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';
import { debounce } from 'lodash';
import Item from './item';
import * as actionTypes from '../../store/actions';

import { getRefetch, getSearch } from '../../store/selectors';
import LoadingIndicator from '../LoadingIndicator';
import Error from '../Error';

import { GET_FILES } from '../../queries';
import { getFilter } from '../../scripts/utils';

import layout from '../../config/file-layout.json';

const display = layout.file;

// Utils
const MAXITEMS = 24;
const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
  },
  actions: {
    alignItems: 'flex-end',
    margin: 'auto 0 0 0',
  },
  buttons: {
    margin: '20px -5px',
  },
  button: {
    margin: 5,
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '250px',
    margin: '20px auto 0',
  },
});

const Listing = props => {
  const {
    classes,
    limit,
    searchValue,
    multiple,
    needsRefecthing,
    onClearFetch,
    onChange,
  } = props;
  const [filter, setFilter] = useState();

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_FILES, {
    variables: { offset: 0, limit, filter },
  });

  useEffect(() => {
    const handleFilter = debounce(() => {
      const f = getFilter('file', searchValue, [], display.fields);
      setFilter(f);
    }, 1500);

    handleFilter();
  }, [searchValue]);

  const onLoadMore = offset => {
    const variables = {
      limit,
      offset,
    };
    if (fetchMore === undefined) return;
    fetchMore({
      // query,
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        const files =
          variables.offset === 0
            ? fetchMoreResult.files
            : previousResult.files.concat(fetchMoreResult.files);

        return {
          allItems: fetchMoreResult.allItems,
          files,
        };
      },
    });
  };

  useEffect(() => {
    if (needsRefecthing) {
      refetch();
      onClearFetch();
    }
    return () => {};
  }, [onClearFetch, refetch, needsRefecthing]);

  const handleMore = o => {
    onLoadMore(o);
  };

  if (loading) return <LoadingIndicator />;
  if (error) return <Error message={error.message} />;

  const { files: items, allItems } = data;
  const count = items ? items.length : 0;
  const max = allItems ? allItems.aggregate.count : 0;

  return (
    <>
      <h4>{max > 0 ? `Show ${count} of ${max} files` : 'No files found'}</h4>
      <Grid container spacing={2}>
        {items &&
          items.length !== 0 &&
          items.map(item => (
            <Grid key={item.id} item xs={6} sm={4} md={3} lg={2}>
              <Item item={item} multiple={multiple} onChange={onChange} />
            </Grid>
          ))}
      </Grid>
      {count < max && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            type="button"
            color="primary"
            className={classes.button}
            onClick={() => handleMore(count)}
            aria-label="Load more"
          >
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

Listing.defaultProps = {
  limit: MAXITEMS,
  multiple: false,
  onChange: undefined,
  searchValue: '',
};

Listing.propTypes = {
  classes: PropTypes.object.isRequired,
  onClearFetch: PropTypes.func.isRequired,
  needsRefecthing: PropTypes.bool.isRequired,
  limit: PropTypes.number,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  searchValue: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    needsRefecthing: getRefetch(state, 'files'),
    searchValue: getSearch(state, 'files'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClearFetch: () =>
      dispatch({
        type: actionTypes.CLEAR_REFETCH,
        store: 'files',
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Listing));
