export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
export const TOGGLE_FILTERITEM = 'TOGGLE_FILTERITEM';
export const SET_FILTERITEM = 'SET_FILTERITEM';

export const ADD_FILTERITEM = 'ADD_FILTERITEM';
export const REMOVE_FILTERITEM = 'REMOVE_FILTERITEM';
export const SET_FILTERSEARCH = 'SET_FILTERSEARCH';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_REFETCH = 'CLEAR_REFETCH';

export const LOGIN = 'LOGIN';
export const SET_ROLE = 'SET_ROLE';
export const SET_USER = 'SET_USER';
export const DECREASE_CREDITS = 'DECREASE_CREDITS';
