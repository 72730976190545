/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * Files
 *
 */

import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';

import { Card, CardContent } from '@material-ui/core';
import Search from '../Search';
import Upload from '../Upload';
import Favorites from './favorites';

import Listing from './listing';
import layout from '../../config/file-layout.json';

const display = layout.file;

// Utils
const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    bottom: 20,
    left: 0,
    right: 0,
    margin: '0 auto',
  },

  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
  },
});

const Files = props => {
  const { classes, onChange } = props;
  return (
    <Paper className={classes.container} elevation={2}>
      <Grid item xs={12}>
        <h3>Upload</h3>
        <Card className={classes.card} elevation={2}>
          <CardContent>
            <Upload />
          </CardContent>
        </Card>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Files</h3>
          <Search store="files" display={display} filterPath="/files/filter" />
          <Listing onChange={onChange} />
        </Grid>
      </Grid>
      <Favorites />
    </Paper>
  );
};

Files.defaultProps = {
  onChange: undefined,
};

Files.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default withStyles(styles)(Files);
