import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingIndicator from '../LoadingIndicator';
import AccesDenied from '../AccesDenied';
import Can from '../Can';

const MyRoute = ({ component: Component, path, perform, ...rest }) => {
  const render = props => (
    <Can
      perform={perform}
      yes={() => <Component {...props} />}
      no={() => <AccesDenied />}
    />
  );
  return <Route path={path} render={render} {...rest} />;
};

MyRoute.defaultProps = {};

MyRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.any,
  ]).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  perform: PropTypes.string.isRequired,
};

const PrivateRoute = withAuthenticationRequired(MyRoute, {
  // Show a message while the user waits to be redirected to the login page.
  // eslint-disable-next-line react/display-name
  onRedirecting: () => <LoadingIndicator />,
});

export default process.env.REACT_APP_SECRET ? MyRoute : PrivateRoute;
