/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { Auth0Provider } from '../../hooks/AuthContext';

import withRoot from '../withRoot';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import NotFoundPage from '../NotFoundPage';
import HomePage from '../HomePage';
import ProductDetailsPage from '../ProductDetailsPage';
import IngredientDetailsPage from '../IngredientDetailsPage';
import ProductsPage from '../ProductsPage';
import IngredientsPage from '../IngredientsPage';
import ProductForm from '../ProductForm';
import IngredientForm from '../IngredientForm';
import FormulaForm from '../FormulaForm';
import MaterialForm from '../MaterialForm';
import MaterialsPage from '../MaterialsPage';
import MaterialDetailsPage from '../MaterialDetailsPage';
import ImportPage from '../ImportPage';
import FilesPage from '../FilesPage';

import PrivateRoute from '../../components/PrivateRoute';

import GlobalStyles from '../../styles/global-styles';
import ProductComparePage from '../ProductComparePage';
import ProductDetailsDemoPage from '../ProductDetailsDemoPage';
import IngredientDetailsDemoPage from '../IngredientDetailsDemoPage';
import ProductCompareDemoPage from '../ProductCompareDemoPage';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(4),
  },
});

const App = props => {
  const { classes } = props;

  return (
    <Auth0Provider>
      <div className={classes.root}>
        <Header />
        <Container maxWidth="xl">
          <main className={classes.main}>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/product/detail-demo" component={ProductDetailsDemoPage} />
              <Route exact path="/product/compare-demo" component={ProductCompareDemoPage} />
              <Route exact path="/ingredient/detail-demo" component={IngredientDetailsDemoPage} />
              <PrivateRoute
                exact
                path="/products"
                component={ProductsPage}
                perform="product:list"
              />
              <PrivateRoute
                exact
                path="/products/filter"
                component={ProductsPage}
                perform="product:filter"
              />
              <PrivateRoute
                exact
                path="/product/:id(\d+)"
                component={ProductDetailsPage}
                perform="product:detail"
              />
              <PrivateRoute
                path="/product/:id(\d+)/ingredient/:iid(\d+)"
                component={ProductDetailsPage}
                perform="formula:zoom"
              />
              <PrivateRoute
                path="/product/compare"
                component={ProductComparePage}
                perform="product:compare"
              />
              <PrivateRoute
                exact
                path="/ingredients"
                component={IngredientsPage}
                perform="ingredient:list"
              />
              <PrivateRoute
                exact
                path="/ingredients/filter"
                component={IngredientsPage}
                perform="ingredient:filter"
              />
              <PrivateRoute
                exact
                path="/ingredients/details/:id(\d+)"
                component={IngredientsPage}
                perform="ingredient:zoom"
              />
              <PrivateRoute
                exact
                path="/ingredient/:id(\d+)"
                component={IngredientDetailsPage}
                perform="ingredient:detail"
              />
              <PrivateRoute
                path="/update/product/:id(\d+)"
                component={ProductForm}
                perform="product:edit"
              />
              <PrivateRoute
                path="/update/product/"
                component={ProductForm}
                perform="product:edit"
              />
              <PrivateRoute
                path="/update/formula/:pid(\d+)/:id(\d+)"
                component={FormulaForm}
                perform="formula:edit"
              />
              <PrivateRoute
                path="/update/formula/:pid(\d+)"
                component={FormulaForm}
                perform="formula:edit"
              />
              <PrivateRoute
                path="/update/formula/"
                component={FormulaForm}
                perform="formula:edit"
              />
              <PrivateRoute
                path="/update/ingredient/:id(\d+)"
                component={IngredientForm}
                perform="ingredient:edit"
              />
              <PrivateRoute
                path="/update/ingredient/"
                component={IngredientForm}
                perform="ingredient:edit"
              />
              <PrivateRoute
                path="/update/material/:id(\d+)"
                component={MaterialForm}
                perform="material:edit"
              />
              <PrivateRoute
                path="/update/material/"
                component={MaterialForm}
                perform="material:edit"
              />

              <PrivateRoute
                exact
                path="/materials"
                component={MaterialsPage}
                perform="material:list"
              />
              <PrivateRoute
                exact
                path="/materials/filter"
                component={MaterialsPage}
                perform="material:filter"
              />
              <PrivateRoute
                exact
                path="/material/:id(\d+)"
                component={MaterialDetailsPage}
                perform="material:detail"
              />

              <PrivateRoute
                exact
                path="/files"
                component={FilesPage}
                perform="files:list"
              />
              <PrivateRoute
                exact
                path="/files/filter"
                component={FilesPage}
                perform="files:filter"
              />

              <PrivateRoute
                path="/import"
                component={ImportPage}
                perform="import:page"
              />
              <Route path="" component={NotFoundPage} />
            </Switch>
          </main>
        </Container>
        <Footer />
        <GlobalStyles />
      </div>
    </Auth0Provider>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(App));
