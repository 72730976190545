/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

function Fixed({ value, decimals, empty }) {
  if (value) return <>{value.toFixed(decimals)}</>;
  if (empty) return <>{empty}</>;
  return null;
}
Fixed.defaultProps = {
  value: null,
  empty: null,
  decimals: 1,
};

Fixed.propTypes = {
  value: PropTypes.number,
  empty: PropTypes.string,
  decimals: PropTypes.number,
};

export default Fixed;
