/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * filesPage
 *
 */

import React from 'react';
import Helmet from 'react-helmet';
import Files from '../../components/Files';

const FilesPage = () => {
  return (
    <>
      <Helmet>
        <title>File Overview Page</title>
        <meta name="description" content="File Overview Page" />
      </Helmet>
      <Files />
    </>
  );
};

export default FilesPage;
