/**
 *
 * Checkboxes
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';
import { Button, FormControl, InputBase } from '@material-ui/core';
import { debounce } from 'lodash';
import LoadingIndicator from '../LoadingIndicator';
import Error from '../Error';
import StaticCheckboxes from './checkboxes';

const styles = () => ({
  inputText: {
    marginLeft: 8,
    flex: 1,
    width: '100%',
  },
  searchFormControl: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
    marginBottom: '10px',
  },
});

const SearchBox = props => {
  const { classes, onHandleSearch } = props;
  const [search, setSearch] = useState('');

  const handleSearch = event => {
    const s = event.target.value;
    setSearch(s);
    onHandleSearch(s);
  };

  return (
    <FormControl component="fieldset" className={classes.searchFormControl}>
      <InputBase
        id="search"
        className={classes.inputText}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'Search' }}
        value={search}
        onChange={e => handleSearch(e)}
      />
    </FormControl>
  );
};

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  onHandleSearch: PropTypes.func.isRequired,
};

const StyledSearchBox = withStyles(styles)(SearchBox);

const CheckboxesQuery = props => {
  const [values, setValues] = useState([]);
  const { classes, limit, query, store, title, field, search, type } = props;
  const { loading, error, data, fetchMore } = useQuery(query, {
    variables: {
      offset: 0,
      limit: 5000,
      name: `%${search}%`,
    },
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setValues(data.options);
    }
    return () => {};
  }, [data, loading, error]);

  const onLoadMore = offset => {
    const variables = {
      limit,
      offset,
    };
    if (fetchMore === undefined) return;
    fetchMore({
      // query,
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        const options =
          variables.offset === 0
            ? fetchMoreResult.options
            : previousResult.options.concat(fetchMoreResult.options);

        return {
          allItems: fetchMoreResult.allItems,
          options,
        };
      },
    });
  };

  const handleMore = o => {
    onLoadMore(o);
  };

  if (loading) return <LoadingIndicator />;
  if (error) return <Error message={error.message} />;

  const count = values ? values.length : 0;
  const max = 1000;

  return (
    <>
      <StaticCheckboxes
        values={values}
        className={classes}
        field={field}
        store={store}
        title={title}
        type={type}
      />
      {false && count < max && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            type="button"
            color="primary"
            className={classes.button}
            onClick={() => handleMore(count)}
            aria-label="Load more"
          >
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

CheckboxesQuery.defaultProps = {
  limit: 50,
  query: undefined,
  search: '',
};

CheckboxesQuery.propTypes = {
  classes: PropTypes.object.isRequired,
  limit: PropTypes.number,
  query: PropTypes.object,
  store: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  search: PropTypes.string,
};

const StyledCheckboxesQuery = withStyles(styles)(CheckboxesQuery);

const Checkboxes = props => {
  const { classes, query, store, title, field } = props;
  const [search, setSearch] = useState('');

  const handleSearchFilter = debounce(s => {
    setSearch(s);
  }, 500);

  return (
    <>
      <StyledSearchBox onHandleSearch={s => handleSearchFilter(s)} />
      <StyledCheckboxesQuery
        className={classes}
        field={field}
        query={query}
        store={store}
        title={title}
        search={search}
      />
    </>
  );
};

Checkboxes.defaultProps = {
  query: undefined,
};

Checkboxes.propTypes = {
  classes: PropTypes.object.isRequired,
  query: PropTypes.object,
  store: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(Checkboxes);
