/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * MaterialsPage
 *
 */

import React from 'react';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Search from '../../components/Search';

import Listing from './listing';

import { GET_MATERIAL_COUNTS } from '../../queries';

import layout from '../../config/material-layout.json';

const display = layout.material;

// Utils
const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    bottom: 20,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
});

const MaterialsPage = props => {
  const { classes } = props;

  return (
    <>
      <Helmet>
        <title>Raw Materials Overview Page</title>
        <meta name="description" content="Raw Materials Overview Page" />
      </Helmet>

      <Paper className={classes.container} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>Raw Materials</h3>
            <Search
              store="materials"
              query={GET_MATERIAL_COUNTS}
              display={display}
              filterPath="/materials/filter"
            />
            <Listing />
          </Grid>
        </Grid>
        <Fab
          component={Link}
          color="primary"
          className={classes.fabButton}
          to="/update/material/"
          aria-label="Add material"
        >
          <AddIcon />
        </Fab>
      </Paper>
    </>
  );
};

MaterialsPage.defaultProps = {};

MaterialsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaterialsPage);
