/**
 *
 * FilterItem
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import FilterType from './filterType';

import { isFieldChecked } from '../../store/selectors';

// Utils

const styles = theme => ({
  filterLegend: {
    fontSize: 14,
    padding: '10px 10px',
    display: 'flex',
    alignItems: 'center',
    // borderBottom: '1px solid #ddd',
    cursor: 'pointer',
    '& svg': {
      margin: '0 0 0 auto',
    },
  },
  fieldset: {
    width: '100%',
  },
  formLabel: {
    fontSize: 14,
    margin: '3px 0 3px -6px',
    '& span': { fontSize: 12 },
  },
  collapsableFormControl: {
    flex: '0 0 100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    border: '1px solid #A5D8DA',
    marginBottom: -1,
    // boxShadow: '0 3px 5px 0 rgba(0,0,0,.15)',
    '& Label span': {
      fontSize: 14,
    },
  },
  nonCollapsableFormControl: {
    flex: 1,
    '& Label span': {
      fontSize: 14,
    },
  },
  formControl: {
    flex: '0 0 100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    border: '1px solid #A5D8DA',
    margin: '15px 15px 20px 15px',
    // boxShadow: '0 3px 5px 0 rgba(0,0,0,.15)',
  },
  formGroupFixed: {
    padding: '5px 15px 5px 15px',
    // margin: '0 0 20px 0',
    '& > div': {
      marginBottom: 20,
    },
  },

  formGroupScroller: {
    // maxHeight: '10em',
    overflow: 'auto',
    transform: 'translateZ(0)',
    padding: '5px 10px 5px 10px',
    margin: '0 10px 20px 0',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar:vertical': {
      width: 11,
    },
    '&::-webkit-scrollbar:horizontal': {
      height: 11,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      border: '2px solid white',
      backgroundColor: 'rgba(165, 216, 218, 0.35)',
    },
  },
  inputText: {
    marginLeft: 8,
    flex: 1,
    width: '100%',
  },
  graphSelect: {
    '& > div': {
      borderColor: '#A5D8DA!important',
    },
    '& .css-bgvzuu-indicatorSeparator': {
      backgroundColor: '#A5D8DA',
    },
    '& svg': {
      color: '#27827B',
    },
  },
});


const FilterItem = props => {
  // const [value, setValue] = useState(propsValue || []);
  const {
    classes,
    options,
    title,
    field,
    type,
    store,
    isCollapsable,
    isDefaultOpen,
  } = props;
  const [open, setOpen] = useState(isDefaultOpen);

  const handleCollapseChange = () => {
    setOpen(!open);
  };

  return (
    <FormControl
      component="fieldset"
      className={
        isCollapsable
          ? classes.collapsableFormControl
          : classes.nonCollapsableFormControl
      }
    >
      {title !== undefined && (
        <FormLabel
          component="div"
          className={classes.filterLegend}
          onClick={() => handleCollapseChange()}
        >
          <span>{title}</span>
          {isCollapsable &&
            (open ? (
              <ExpandLess color="primary" />
            ) : (
              <ExpandMore color="primary" />
            ))}
        </FormLabel>
      )}
      <Collapse in={open || !isCollapsable} timeout="auto" unmountOnExit>
        <FilterType type={type} classes={classes} options={options} field={field} store={store} title={title} />
      </Collapse>
    </FormControl>
  );
};

FilterItem.defaultProps = {
  isCollapsable: true,
  title: undefined,
  options: [],
  isDefaultOpen: false,
};

FilterItem.propTypes = {
  type: PropTypes.string.isRequired,
  isCollapsable: PropTypes.bool,
  title: PropTypes.string,
  field: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  store: PropTypes.string.isRequired,
  isDefaultOpen: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  return {
    isDefaultOpen: isFieldChecked(state, props.store, props.field),
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(FilterItem));
