/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import makeApolloClient from '../scripts/apollo';
import { GRAPHQL_URL, REALTIME_GRAPHQL_URL } from '../config/constants';
import { getAccess } from '../store/selectors';

const USERS = gql`
  query {
    users(order_by: { name: asc }) {
      id
      name
      role
    }
  }
`;

const roles = ['admin', 'user', 'max', 'plus', 'basic'];

export const setRole = r => {
  const role = roles.includes(r) ? r : 'user';
  localStorage.setItem('role', role);
};

export const getRole = () => {
  const r = localStorage.getItem('role');
  const role = roles.includes(r) ? r : 'user';

  return role;
};

export const Auth0Context = React.createContext();

export const Auth0Provider = ({ children }) => {
  const [user, setUser] = useState({
    role: 'user',
  });
  const [client, setClient] = useState();

  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    const apolloClient = makeApolloClient(
      GRAPHQL_URL,
      REALTIME_GRAPHQL_URL,
      getAccessTokenSilently,
      getRole,
    );
    setClient(apolloClient);
    setRole('user');
    return () => {};
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      client
        .query({
          query: USERS,
        })
        .then(result => {
          if (!result.loading) {
            const { users } = result.data;
            const role = users.length > 1 ? 'admin' : users[0].role;
            setUser(prevUser => {
              return {
                ...prevUser,
                role,
                access: getAccess(role),
              };
            });

            setRole(role);
          }
        })
        .catch(e => {
          // GraphQL errors can be extracted here
          if (e.graphQLErrors) {
            // reduce to get message
            console.log('Error', e, e.graphQLErrors);
          }
        });
    }
    return () => {};
  }, [client, isAuthenticated]);

  if (!client) {
    return null;
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loginWithRedirect: (...p) => loginWithRedirect(...p),
        getAccessTokenSilently: (...p) => getAccessTokenSilently(...p),
        logout: (...p) => logout(...p),
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.object.isRequired,
};
