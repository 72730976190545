/**
 *
 * IngredientsPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Can from '../Can';

import layout from '../../config/ingredient-layout.json';

const display = layout.ingredient;

// Utils
const styles = () => ({
  smalltable: {
    width: 'auto',
    marginBottom: 50,
    '& td, & th': {
      borderColor: '#A5D8DA',
      // whiteSpace: 'nowrap',
    },
  },
  editRow: {
    '& td, & th': {
      border: 0,
    },
  },
  labelCell: {
    whiteSpace: 'nowrap;',
  },
});

function IngredientsSummary({ classes, ingredient }) {
  return (
    <Table className={classes.smalltable} size="small">
      <TableBody>
        {Object.keys(display.fields).map(name =>
          display.fields[name].type !== 'subform' &&
          display.fields[name].type !== 'aggregate' &&
          ingredient[name] &&
          ingredient[name] !== '' ? (
            <TableRow key={display.fields[name].label}>
              <TableCell className={classes.labelCell}>
                {display.fields[name].label}:
              </TableCell>
              <TableCell>{ingredient[name]}</TableCell>
            </TableRow>
          ) : null,
        )}

        <Can
          perform="ingredient:detail"
          yes={() => (
            <TableRow key="edit" className={classes.editRow}>
              <TableCell />
              <TableCell>
                <Link to={`/ingredient/${ingredient.id}/`}>
                  <IconButton aria-label="Details" color="primary">
                    <InfoIcon />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          )}
        />
      </TableBody>
    </Table>
  );
}

IngredientsSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredient: PropTypes.object.isRequired,
};

export default withStyles(styles)(IngredientsSummary);
