import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    height: 100%;
    width: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #27827b;

  }
  a {
    color: #27827b;
    text-decoration: none;
  }
  
  a:hover {
    color: #66bfb6;
  }

  body {
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  }

  .MuiInputBase-input {
    min-height: 30px;
  }

`;

export default GlobalStyle;
