import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { check } from '../../store/selectors';
import { Auth0Context } from '../../hooks/AuthContext';

const Can = props => {
  return props.onCheck ? props.yes() : props.no();
};

const mapStateToProps = (state, ownProps) => {
  return {
    onCheck: check(state, ownProps.role, ownProps.perform, ownProps.data),
  };
};

const ReduxCan = connect(mapStateToProps, null)(Can);

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

const CanWithContext = props => {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;
  return <ReduxCan {...props} role={user.role} />;
};

export default CanWithContext;
