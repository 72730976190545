import { Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Helmet } from 'react-helmet';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  intro: {},
  title: {
    textAlign: 'left',
  },
});

const HomePage = props => {
  const { classes } = props;

  return (
    <article>
      <Helmet>
        <title>Home Page</title>
        <meta name="description" content="" />
      </Helmet>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={8} lg={6}>
          <Paper className={classes.container} elevation={2}>
            <h1 className={classes.title}>
              Welcome to the FRAMES Formulation database
            </h1>
            <div>
              <h3 className={classes.intro}>
                Please read below introductory comments and click on the
                attached video tutorial for user guidance.
              </h3>
              <p>
                Significant Formulation Intelligence and great care has gone
                into the design and user friendliness of this database. If you
                run into any disorders, have comments about user guidence, or
                miss a particular product - ingredient, please let us know via
                the ‘contact us’ button in the footer.
              </p>
              <div
                classes="video"
                style={{
                  position: 'relative',
                  paddingBottom: '56.25%' /* 16:9 */,
                  paddingTop: 25,
                  height: 0,
                }}
              >
                <iframe
                  title="Frames tutorial"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  src="https://www.youtube.com/embed/puneZMef53Q"
                  frameBorder="0"
                />

              </div>
              <p>
                We have installed Google Analytics to improve the database’s
                functionality. We only analyse the total monthly numbers of
                clicks on product-ingredient filters, we do not monitor specific
                searches for any user.
              </p>
              <p>
                Formulation % and cost assignments should be regarded as indicative at an
                accuracy of ± 20%. If you require more accurate assignments,
                please contact us for chemical analyses.
              </p>
            </div>
            <Typography variant="subtitle2">
              © Frames Formulation Intelligence B.V. 2020. All rights reserved.
              The material contained in this database is the exclusive property
              of Frames Formulation Intelligence B.V. and is provided without
              any warranties or representations about accuracy or completeness.
              Any reliance on such material is made at users’ own risk. This
              database is confidential and for internal use by client only.
              Publication or making available to any third party of all or part
              of the material contained in this database (or any data or other
              material derived from it) without Frames Formulation
              Intelligence’s express written consent is strictly prohibited.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </article>
  );
};

HomePage.defaultProps = {};

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePage);
