import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Item from './item';
import { getFavorites } from '../../store/selectors';
import Can from '../../components/Can';
import DemoDialog from '../../components/DemoDialog';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  fabButton: {
    margin: '0 auto',
  },
  buttons: {
    margin: '20px -5px',
  },
  button: {
    margin: 5,
  },
});

const Favorites = props => {
  const { classes, favorites } = props;

  if (favorites.length === 0) return null;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Favourites</h3>
          <Grid container spacing={2}>
            {favorites &&
              favorites.length !== 0 &&
              favorites.map(item => {
                /*
                const d = new Date(Date.parse(item.created_at));
                console.log('item', item.created_at, d);
                const createdAt = format(d, 'yyyy-MM-dd');
                */
                return (
                  <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                    <Item item={item} isFavourite />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Can
          perform="product:compare"
          yes={() => (
            <Button
              component={Link}
              variant="contained"
              type="button"
              color="primary"
              className={classes.button}
              to="/product/compare"
              aria-label="Compare"
            >
              Compare
            </Button>
          )}
          no={() => (
            <DemoDialog to="/product/compare-demo">
                Compare
            </DemoDialog>
          )}
        />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    favorites: getFavorites(state, 'products'),
  };
};

Favorites.defaultProps = {};

Favorites.propTypes = {
  classes: PropTypes.object.isRequired,
  favorites: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(Favorites));
