/**
 *
 * Checkboxes
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from './checkbox';

const styles = theme => ({
  formControl: {
    flex: '0 0 100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    border: '1px solid #A5D8DA',
    margin: '15px 15px 20px 15px',
    // boxShadow: '0 3px 5px 0 rgba(0,0,0,.15)',
  },
});

const Checkboxes = props => {
  const { values, store, field, title } = props;
  return (
    <>
      {values.map(value => (
        <Checkbox
          key={value.value}
          value={value}
          store={store}
          field={field}
          title={title}
        />
      ))}
    </>
  );
};

Checkboxes.defaultProps = {
  values: [],
};

Checkboxes.propTypes = {
  values: PropTypes.array,
  store: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(Checkboxes);
