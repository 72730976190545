import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Query } from '@apollo/client/react/components';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TagManager from 'react-gtm-module';
import { Auth0Context } from '../../hooks/AuthContext';
import Price from '../../components/Price';

import history from '../../scripts/history';

import LoadingIndicator from '../../components/LoadingIndicator';
import Error from '../../components/Error';
import Concat from '../../components/Concat';

import { GET_INGREDIENT } from '../../queries';

// Layout
import layout from '../../config/ingredient-layout.json';
import Can from '../../components/Can';
import { getIngredientImpressions } from '../../scripts/utils';

const display = layout.ingredient;

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  detailsTable: {
    tableLayout: 'fixed',
    marginBottom: 50,
    '& td, & th': {
      borderColor: '#A5D8DA',
      minWidth: '250px',
    },
    [theme.breakpoints.down('md')]: {
      tableLayout: 'auto',
      '& td, & th': {},
    },
  },

  tableHead: {
    // whiteSpace: 'nowrap',
  },

  headCell: {
    // whiteSpace: 'nowrap',
  },

  actionCell: {
    width: 50,
    paddingTop: 0,
    paddingBottom: 0,
  },

  smalltable: {
    width: 'auto',
    marginBottom: 50,
    '& td, & th': {
      borderColor: '#A5D8DA',
    },
  },

  editButton: {
    // position: 'absolute',
    top: '0',
    right: '0',
  },

  editRow: {
    '& td, & th': {
      border: 0,
    },
  },

  fabButton: {
    position: 'absolute',
    zIndex: 1,
    bottom: 20,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
  },
});

const StyledConcat = styled(Concat)`
  display: block;
`;

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(165, 216, 218, 0.15)',
    },
  },
}))(TableRow);

const StyledPrice = withStyles(() => ({
  textAlign: 'right',
}))(Price);

function IngredientDetailsPage(props) {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;

  const {
    classes,
    match: {
      params: { id },
    },
  } = props;

  return (
    <Query
      query={GET_INGREDIENT({
        with_price_active:
          (user && user.access && user.access.ingredient_price_active) || false,
      })}
      variables={{
        id,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingIndicator />;
        }

        if (error) {
          return <Error message={error.message} />;
        }

        if (!data.ingredient || data.ingredient.length !== 1) {
          history.replace(`/ingredients`);
          return <Error message="Error loading ingredient..." />;
        }

        const ingredient = data.ingredient[0];
        TagManager.dataLayer({
          dataLayer: {
            event: 'ingredientDetails',
            ecommerce: {
              detail: {
                actionField: { list: 'Ingredient Details' }, // 'detail' actions have an optional list property.
                products: [
                  getIngredientImpressions(
                    [ingredient],
                    'Ingredient Details',
                    1,
                  ),
                ],
              },
            },
          },
        });
        return (
          <>
            <Helmet>
              <title>Ingredient Details Page</title>
              <meta name="description" content="" />
            </Helmet>
            <Paper className={classes.container} elevation={2}>
              <h3>Ingredient</h3>
              <div className={classes.tableWrapper}>
                <Table className={classes.smalltable} size="small">
                  <TableBody>
                    {Object.keys(display.fields).map(name =>
                      display.fields[name].type !== 'subform' &&
                      display.fields[name].type !== 'aggregate' &&
                      ingredient[name] &&
                      ingredient[name] !== '' ? (
                        <Can
                          key={display.fields[name].label}
                          perform={`ingredient.field.${name}`}
                          yes={() => (
                            <TableRow>
                              <TableCell>
                                {display.fields[name].label}:
                              </TableCell>
                              <TableCell>{ingredient[name]}</TableCell>
                            </TableRow>
                          )}
                        />
                      ) : null,
                    )}

                    <Can
                      perform="ingredient:edit"
                      yes={() => (
                        <TableRow key="edit" className={classes.editRow}>
                          <TableCell />
                          <TableCell>
                            <Link
                              className={classes.editButton}
                              to={`/update/ingredient/${id}`}
                            >
                              <IconButton
                                size="small"
                                aria-label="Details"
                                color="primary"
                              >
                                <EditIcon />
                              </IconButton>
                            </Link>
                          </TableCell>
                        </TableRow>
                      )}
                    />
                  </TableBody>
                </Table>
              </div>

              <Can
                perform="ingredient:products"
                yes={() => (
                  <>
                    <h3>Products</h3>
                    <div className={classes.tableWrapper}>
                      <Table className={classes.detailsTable} size="small">
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell className={classes.headCell}>
                              Category / Subcategory
                            </TableCell>
                            <TableCell className={classes.headCell}>
                              Format / Subformat
                            </TableCell>
                            <TableCell className={classes.headCell}>
                              Brand name / Variant name
                            </TableCell>
                            <TableCell className={classes.headCell}>
                              Brand owner
                            </TableCell>
                            <TableCell className={classes.headCell}>
                              Country
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ingredient.formulas &&
                            ingredient.formulas.map(formula => {
                              if (!formula.product) {
                                console.log('No producg found for', formula);
                                return null;
                              }
                              return (
                                <StyledTableRow key={formula.id}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    className={classes.headCell}
                                  >
                                    <span className={classes.linkIcon}>
                                      <Can
                                        perform="product:detail"
                                        yes={() => (
                                          <Link
                                            className={classes.editButton}
                                            to={`/product/${formula.product.id}`}
                                          >
                                            <IconButton
                                              size="small"
                                              aria-label="Details Ingredient"
                                              color="primary"
                                            >
                                              <InfoIcon />
                                            </IconButton>
                                          </Link>
                                        )}
                                      />
                                      <span>
                                        <StyledConcat
                                          first={formula.product.category}
                                          second={formula.product.subcategory}
                                          divider=" / "
                                        />
                                      </span>
                                    </span>
                                  </TableCell>
                                  <TableCell className={classes.headCell}>
                                    <StyledConcat
                                      first={formula.product.format}
                                      second={formula.product.subformat}
                                      divider=" / "
                                    />
                                  </TableCell>
                                  <TableCell className={classes.headCell}>
                                    <StyledConcat
                                      first={formula.product.brand_name}
                                      second={formula.product.variant_name}
                                      divider=" / "
                                    />
                                  </TableCell>
                                  <TableCell className={classes.headCell}>
                                    {formula.product.brand_owner}
                                  </TableCell>
                                  <TableCell className={classes.headCell}>
                                    {formula.product.country}
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </div>
                  </>
                )}
              />

              <Can
                perform="material:view"
                yes={() => (
                  <>
                    <h3>Raw Materials</h3>
                    <div className={classes.tableWrapper}>
                      <Table className={classes.detailsTable} size="small">
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell className={classes.headCell}>
                              Tradename
                            </TableCell>
                            <TableCell className={classes.headCell}>
                              Ingredient
                            </TableCell>
                            <TableCell className={classes.headCell}>
                              Suppliername
                            </TableCell>
                            <TableCell className={classes.headCell}>
                              Description
                            </TableCell>
                            <TableCell className={classes.headCell}>
                              Price (raw)
                            </TableCell>
                            <TableCell className={classes.actionCell} />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ingredient.materials.map(material => {
                            return (
                              <StyledTableRow key={material.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.headCell}
                                >
                                  <span className={classes.linkIcon}>
                                    <span> {material.trade_name}</span>
                                  </span>
                                </TableCell>
                                <TableCell className={classes.headCell}>
                                  {material.ingredient.name}
                                </TableCell>
                                <TableCell className={classes.headCell}>
                                  {material.supplier.name}
                                </TableCell>
                                <TableCell className={classes.headCell}>
                                  {material.description}
                                </TableCell>
                                <TableCell className={classes.headCell}>
                                  <StyledPrice value={material.price_raw} />
                                </TableCell>
                                <TableCell className={classes.actionCell}>
                                  <Link
                                    className={classes.editButton}
                                    to={`/material/${material.id}`}
                                  >
                                    <IconButton
                                      size="small"
                                      aria-label="Details Raw Material"
                                      color="primary"
                                    >
                                      <InfoIcon />
                                    </IconButton>
                                  </Link>
                                </TableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>

                      <Fab
                        component={Link}
                        color="primary"
                        className={classes.fabButton}
                        to="/update/material"
                        aria-label="Add raw material"
                      >
                        <AddIcon />
                      </Fab>
                    </div>
                  </>
                )}
              />
            </Paper>
          </>
        );
      }}
    </Query>
  );
}

IngredientDetailsPage.defaultProps = {};

IngredientDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(IngredientDetailsPage);
