import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Range from '../Range';
import Price, { formatPrice } from '../Price';
import Percentage from '../Percentage';
import Can from '../Can';
import { Auth0Context } from '../../hooks/AuthContext';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Box } from '@material-ui/core';

const styles = () => ({
  gridWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
    marginBottom: 50,
  },
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  detailsTable: {
    marginBottom: 50,
    '& td, & th': {
      borderColor: '#A5D8DA',
    },
  },
  tableHead: {
    whiteSpace: 'nowrap',
  },

  headCell: {
    whiteSpace: 'nowrap',
  },

  actionCell: {
    width: 50,
    paddingTop: 0,
    paddingBottom: 0,
  },

  valueCell: {
    maxWidth: 500,
    minWidth: 350,
    whiteSpace: 'nowrap',
  },

  editButton: {
    // position: 'absolute',
    top: '0',
    right: '0',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
  },
});

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(165, 216, 218, 0.15)',
    },
  },
}))(TableRow);

const StyledDatatGrid=withStyles(() => ({
  root: {
    "& .MuiDataGrid-renderingZone": {
      "& .MuiDataGrid-row:not(:hover)": {
        "&:nth-child(2n)": { 
          backgroundColor: 'rgba(165, 216, 218, 0.15)',
        }
      }
    }
  }
}))(DataGrid);

const StyledPrice = withStyles(() => ({
  textAlign: 'right',
}))(Price);


const UPDATE_FORMULA = gql`
mutation updateFormula(
  $id: Int!
  $setInput: formula_set_input
) {
  formula: update_formula(
    where: {
      id: { _eq: $id } 
    }
    _set: $setInput
  ) {
    affected_rows
    formula: returning {
      id
      percentage_min_sds
      percentage_max_sds
      percentage_min_label
      percentage_max_label
      percentage_frames
    }
  }
}
`;



function ProductIngredients(props) {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;
  const { classes, products } = props;
  const [editable, setEditable] = React.useState(false);


  const [updateFormula] = useMutation(UPDATE_FORMULA);

  const handleCellEditCommit =
    ({ id, field, value }) => {
      const val = value==='' ? null : value
      updateFormula({variables: {id: id, 
        "setInput": {
          [field]: val
        }}})
    }
  
  const priceActive = (params) =>{
    return formatPrice({value: params.value});
  }

  const valueFormatter = (params) =>{
    return params.value || '';
  }

  const renderDetailsButtons = (params) => {
    const productId = products[0].id;
    return (
      <>
        <Can
          perform="formula:zoom"
          yes={() => (
            <Link
              className={classes.editButton}
              to={`/product/${productId}/ingredient/${params.row.ingredient_id}`}
            >
              <IconButton
                size="small"
                aria-label="Search"
                color="primary"
              >
                <SearchIcon />
              </IconButton>
            </Link>)}
         />
        <Can
          perform="formula:edit"
          yes={() => (
            <Link
              className={classes.editButton}
              to={`/update/formula/${productId}/${params.row.id}`}
            >
              <IconButton
                size="small"
                aria-label="Details"
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Link>)}
        />      
        <Box>
          {params.row.name}
        </Box>
      </>
    )
}

  const rows = products[0].formulas.map(formula => {
    return (
      {
        ...formula,
        name: formula.ingredient.name,
        ingredient_id: formula.ingredient.id,
        price_active: 
        ((formula.ingredient.price_active *
          formula.percentage_frames) /
        100)
      }
    )
  });

  const columns = [
    { field: 'ingredient', headerName: 'Ingredient', minWidth: 350, flex:1, sortable: false, renderCell: renderDetailsButtons },
    { field: 'ranking', headerName: 'Ranking', width: 120,sortable: false },
    { field: 'percentage_min_sds', headerName: 'SDS % (min)', editable: true, width: 120,sortable: false, valueFormatter: valueFormatter },
    { field: 'percentage_max_sds', headerName: 'SDS % (max)',  editable: true,width: 120,sortable: false, valueFormatter: valueFormatter },
    { field: 'percentage_min_label', headerName: 'Label % (min)',  editable: true,width: 120,sortable: false, valueFormatter: valueFormatter },
    { field: 'percentage_max_label', headerName: 'Label % (max)',  editable: true, width: 120,sortable: false, valueFormatter: valueFormatter },
    { field: 'percentage_frames', headerName: 'Frames %',  editable: true, width: 120,sortable: false, valueFormatter: valueFormatter },
    { field: 'price_active', headerName: 'Frames €/ton',  editable: false, width: 120,sortable: false,
      valueFormatter: priceActive
    },
  ];

  return (
    <>
      <Can
        perform="formula:edit"
        yes={() => (
          <div style={{ display: 'flex', height: '100%', marginBottom: 50 }}>
          <div style={{ flexGrow: 1 }}>
            <StyledDatatGrid autoHeight density="compact" rows={rows} columns={columns} onCellEditCommit={handleCellEditCommit} hideFooter={true} disableColumnFilter={true} disableColumnMenu={true} />
          </div>
        </div>
        )}
      />
      
      <div className={classes.tableWrapper}>
        <Table className={classes.detailsTable} size="small">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.valueCell}>Ingredient</TableCell>
              <Can
                perform="product.field.ranking"
                yes={() => (
                  <TableCell className={classes.headCell}>Ranking</TableCell>
                )}
              />
              <Can
                perform="product.field.percentage_sds_ingredients"
                yes={() => (
                  <TableCell className={classes.headCell}>SDS %</TableCell>
                )}
              />
              <Can
                perform="product.field.percentage_label"
                yes={() => (
                  <TableCell className={classes.headCell}>Label %</TableCell>
                )}
              />
              <Can
                perform="product.field.percentage_frames"
                yes={() => (
                  <TableCell className={classes.headCell}>Frames %</TableCell>
                )}
              />
              <Can
                perform="product.field.price_active"
                yes={() => (
                  <TableCell className={classes.headCell}>Frames €/ton</TableCell>
                )}
              />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(product => {
              const formulas = product.formulas.filter(
                formula =>
                  formula.ranking !== null ||
                  (user && user.access && user.access.is_admin), // formula.ingredient.name !== formula.ingredient.type,
              );

              return formulas.map(formula => (
                <StyledTableRow key={formula.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.valueCell}
                  >
                    <span className={classes.linkIcon}>                      
                      <span> {formula.ingredient.name}</span>
                    </span>
                  </TableCell>
                  <Can
                    perform="product.field.ranking"
                    yes={() => (
                      <TableCell className={classes.headCell}>
                        {formula.ranking}
                      </TableCell>
                    )}
                  />
                  <Can
                    perform="product.field.percentage_sds_ingredients"
                    yes={() => (
                      <TableCell className={classes.headCell}>
                        <Range
                          min={formula.percentage_min_sds}
                          max={formula.percentage_max_sds}
                        />
                      </TableCell>
                    )}
                  />
                  <Can
                    perform="product.field.percentage_label"
                    yes={() => (
                      <TableCell className={classes.headCell}>
                        <Range
                          min={formula.percentage_min_label}
                          max={formula.percentage_max_label}
                        />
                      </TableCell>
                    )}
                  />
                  <Can
                    perform="product.field.percentage_frames"
                    yes={() => (
                      <TableCell className={classes.headCell}>
                        <Percentage value={formula.percentage_frames} />
                      </TableCell>
                    )}
                  />
                  <Can
                    perform="product.field.price_active"
                    yes={() => (
                      <TableCell className={classes.headCell}>
                        <StyledPrice
                          value={
                            (formula.ingredient.price_active *
                              formula.percentage_frames) /
                            100
                          }
                          decimals={0}
                        />
                      </TableCell>
                    )}
                  />
                  <TableCell />
                </StyledTableRow>
              ));
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
}

ProductIngredients.defaultProps = {};

ProductIngredients.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProductIngredients);
