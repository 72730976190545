/**
 *
 * Checkboxes
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import * as actionTypes from '../../store/actions';
import { getValue } from '../../store/selectors';

const styles = () => ({
  root: {
    marginTop: 50,
    marginRight: 20,
    marginLeft: 20,
  },
});

function valuetext(value) {
  return `${value}`;
}

const Range = props => {
  const { min, max, value, classes, onFilterToggle } = props;

  const initialValue = Array.isArray(value) ? value : [min, max];
  const [sliderValue, setSliderValue] = React.useState(initialValue);

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
    onFilterToggle(newValue);
  };

  return (
    <div className={classes.root}>
      <Slider
        value={sliderValue}
        onChange={handleChange}
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        valueLabelDisplay="on"
        min={min}
        max={max}
      />
    </div>
  );
};

Range.defaultProps = {
  value: {},
};

Range.propTypes = {
  onFilterToggle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number,
};

const mapStateToProps = (state, props) => {
  return {
    value: getValue(state, props.store, props.field),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onFilterToggle: value => {
      dispatch({
        type: actionTypes.SET_FILTERITEM,
        store: props.store,
        item: {
          id: props.field,
          value,
          type: props.type,
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'search',
          category: props.title,
          search: value.label,
        },
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Range));
