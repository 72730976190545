import React from 'react';
import { Helmet } from 'react-helmet';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import grey from '@material-ui/core/colors/grey';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme({
  typography: {
    fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    subtitle2: {
      fontSize: 12,
      color: grey[500],
    },
  },
  palette: {
    primary: {
      main: '#27827B',
    },
    secondary: {
      main: '#A5D8DA',
    },
  },
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <Helmet
          titleTemplate="%s - Frames Formulation Intelligence"
          defaultTitle="Home"
        >
          <meta
            name="description"
            content="Frames Formulation Intelligence B.V."
          />
        </Helmet>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
