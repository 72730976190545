/**
 *
 * ProductsPage
 *
 */

import React, { useState, useEffect, useContext } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import format from 'date-fns/format';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TagManager from 'react-gtm-module';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { Auth0Context } from '../../hooks/AuthContext';
import VisibilitySensor from '../../components/visibilitySensor';
import LoadingIndicator from '../../components/LoadingIndicator';
import Error from '../../components/Error';
import Item from './item';
import { getProductImpressions, getFilter } from '../../scripts/utils';
import Can from '../../components/Can';

import { getSearch, getValues } from '../../store/selectors';

import { GET_PRODUCTS } from '../../queries';
import layout from '../../config/product-layout';

const display = layout.product;

// Utils
const MAXITEMS = 24;
const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  buttons: {
    margin: '20px -5px',
  },
  button: {
    margin: 5,
  },
});

const onImpression = (item, i) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'productImpressions',
      ecommerce: {
        impressions: getProductImpressions([item], 'Product List', i),
      },
    },
  });
};

const Listing = props => {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;

  const { classes, limit, values, searchValue } = props;
  const [filter, setFilter] = useState();

  useEffect(() => {
    const handleFilter = debounce(() => {
      const f = getFilter('product', searchValue, values, display.fields);
      setFilter(f);
    }, 500);
    handleFilter();
  }, [searchValue, values]);

  const { loading, error, data, fetchMore } = useQuery(
    GET_PRODUCTS({
      with_price_active:
        (user && user.access && user.access.product_price_active) || false,
      with_percentage: (user && user.access && user.access.percentage) || false,
    }),
    {
      variables: {
        offset: 0,
        limit,
        filter,
      },
      // notiyOnNetworkStatusChange: true,
    },
  );

  const onLoadMore = offset => {
    const variables = {
      limit,
      offset,
    };
    if (fetchMore === undefined) return;
    fetchMore({
      // query,
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        const products =
          variables.offset === 0
            ? fetchMoreResult.products
            : previousResult.products.concat(fetchMoreResult.products);

        return {
          allItems: fetchMoreResult.allItems,
          products,
        };
      },
    });
  };

  const handleMore = o => {
    onLoadMore(o);
  };

  if (loading) return <LoadingIndicator />;
  if (error) return <Error message={error.message} />;

  const { products: items, allItems } = data;
  const count = items ? items.length : 0;
  const max = allItems ? allItems.aggregate.count : 0;

  return (
    <>
      <h4>
        {max > 0 ? `Show ${count} of ${max} products` : 'No products found'}
      </h4>
      <Grid container spacing={2}>
        {items &&
          items.length !== 0 &&
          items.map((item, i) => {
            /*
            const d = new Date(Date.parse(item.created_at));
            console.log('item', item.created_at, d);
            const createdAt = format(d, 'yyyy-MM-dd');
            */

            return (
              <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                <VisibilitySensor partialVisibility once>
                  {({ isVisible }) => {
                    if (isVisible) {
                      onImpression(item, i);
                    }
                    return <Item item={item} />;
                  }}
                </VisibilitySensor>
              </Grid>
            );
          })}
      </Grid>
      <div className={classes.buttons}>
        {count < max && (
          <Can
            perform="product:more"
            yes={() => (
              <Button
                variant="contained"
                type="button"
                color="primary"
                className={classes.button}
                onClick={() => handleMore(count)}
                aria-label="Load more"
              >
                Load more
              </Button>
            )}
          />
        )}
      </div>
    </>
  );
};

Listing.defaultProps = {
  limit: MAXITEMS,
  searchValue: '',
};

Listing.propTypes = {
  classes: PropTypes.object.isRequired,
  limit: PropTypes.number,
  searchValue: PropTypes.string,
  values: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    searchValue: getSearch(state, 'products'),
    values: getValues(state, 'products'),
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Listing));
