/**
 *
 * Filter
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withStyles } from '@material-ui/core/styles';
import FilterItem from '../FilterItem';
import LoadingIndicator from '../LoadingIndicator';
import Error from '../Error';

import { groupBy } from '../../scripts/utils';

const styles = theme => ({
  filterContainer: {
    padding: theme.spacing(2),
    position: 'relative',
    width: 450,
    boxShadow: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

/*
var sort_object = function(map) {
  var keys = _.sortBy(_.keys(map), function(a) {
    return a;
  });
  var newmap = {};
  _.each(keys, function(k) {
    newmap[k] = map[k];
  });
  return newmap;
};
*/

const Filter = props => {
  const { classes, onClose, query, store, display } = props;

  const handleClose = event => {
    onClose(event);
  };

  return (
    <Paper className={classes.filterContainer} elevation={2}>
      <div className={classes.drawerHeader}>
        <Button onClick={e => handleClose(e)}>
          <h3>
            <ChevronLeftIcon />
            Close
          </h3>
        </Button>
      </div>
      <h3>Filter</h3>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Query
            query={query}
            // variables={state.variables}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <LoadingIndicator />;
              }
              if (error) {
                return <Error messge={error.message} />;
              }
          
              const groups =
                data && data.group_count
                  ? groupBy(data.group_count, 'group')
                  : [];

              return (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} className={classes.inputWrapper}>
                    {display.filters.map(filter => {
                      const options = Array.isArray(
                        display.fields[filter].options,
                      )
                        ? display.fields[filter].options.map(v => {
                            return {
                              value: v,
                              label: v,
                            };
                          })
                        : display.fields[filter].options;

                      const values =
                        display.fields[filter] && display.fields[filter].options && Array.isArray(display.fields[filter].options) && display.fields[filter].type!=="filteredSelect"
                          ? options
                          : groups[filter];
                      const filterType = display.fields[filter].type
                      const filterOptions =
                        display.fields[filter] &&
                        display.fields[filter].type === 'filter'
                          ? display.fields[filter].options
                          : values;
                          
                      return (
                        <FilterItem
                          store={store}
                          type={filterType}
                          key={filter}
                          field={filter}
                          title={
                            display.fields[filter]
                              ? display.fields[filter].label
                              : filter
                          }
                          options={filterOptions}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              );
            }}
          </Query>
        </Grid>
      </Grid>
    </Paper>
  );
};

Filter.defaultProps = {};

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  display: PropTypes.object.isRequired,
  store: PropTypes.string.isRequired,
};

export default withStyles(styles)(Filter);
