import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import history from '../../scripts/history';


const styles = theme => ({
    buttons: {
      margin: '20px -5px',
    },
    button: {
      margin: 5,
    },
  });

  
function DemoDialog({children, classes, to, icon}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    history.push(to);

  };

  return (
    <div>
        {icon && (
            <IconButton aria-label="Details" color="primary" onClick={handleClickOpen}>
            {icon}
            </IconButton>
        )}
        {!icon && (
            <Button
                variant="contained"
                type="button"
                color="primary"
                to=""
                aria-label="Compare"
                onClick={handleClickOpen}
                className={classes.button}
            >
                Compare
            </Button>
        )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            Welcome to the DEMO version.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can freely use the filters but only the first 24 products/ingredients will be shown. Clicking on "i" or "heart" will show ‘dummy' output examples. Curious to find out what information is hidden? Please contact us for full access.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button 
                        variant="contained"

                        type="button"
                        color="primary"
        
        onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(DemoDialog);
