import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Query } from '@apollo/client/react/components';
import { Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import history from '../../scripts/history';

import IngredientSummary from '../../components/IngredientSummary';

import LoadingIndicator from '../../components/LoadingIndicator';
import Error from '../../components/Error';

import { GET_INGREDIENT, GET_MATERIAL } from '../../queries';

// Layout
import layout from '../../config/material-layout.json';

const display = layout.material;

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  button: {
    margin: 'theme.spacing(1)px 0',
  },
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  detailsTable: {
    marginBottom: 50,
    '& td, & th': {
      borderColor: '#A5D8DA',
    },
  },

  smalltable: {
    width: 'auto',
    marginBottom: 50,
    '& td, & th': {
      borderColor: '#A5D8DA',
    },
  },

  editButton: {
    // position: 'absolute',
    top: '0',
    right: '0',
  },

  editRow: {
    '& td, & th': {
      border: 0,
    },
  },

  fabButton: {
    position: 'absolute',
    zIndex: 1,
    bottom: 20,
    left: 0,
    right: 0,
    margin: '0 auto',
  },

  filterContainer: {
    padding: theme.spacing(2),
    position: 'relative',
    width: 450,
    boxShadow: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
  },
});

const handleClose = (props, id) => {
  history.push(`/material/${id}`);
};

function MaterialDetailsPage(props) {
  const {
    classes,
    match: {
      params: { id },
    },
  } = props;

  return (
    <Query query={GET_MATERIAL} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingIndicator />;
        }

        if (error) {
          return <Error message={error.message} />;
        }

        if (!data.material || data.material.length !== 1) {
          history.replace(`/materials`);
          return <Error message="Error loading material.." />;
        }

        const material = data.material[0];
        return (
          <>
            <Helmet>
              <title>Material Details Page</title>
              <meta name="description" content="" />
            </Helmet>
            <Route path="/material/:id/ingredient/:iid">
              {({ match }) => {
                const isMatch = match && match.params;
                const iid = match && match.params && match.params.iid;

                return (
                  <Drawer
                    open={!!isMatch}
                    anchor="left"
                    onClose={() => handleClose(props, id)}
                  >
                    <Paper className={classes.filterContainer} elevation={2}>
                      <div className={classes.drawerHeader}>
                        <Link
                          to={`/material/${id}`}
                          onClick={() => handleClose(props, id)}
                          color="primary"
                        >
                          <h3>
                            Close
                            <ChevronRightIcon />
                          </h3>
                        </Link>
                      </div>
                      <h3>Ingredient</h3>
                      {isMatch && id && (
                        <Query
                          query={GET_INGREDIENT({ with_price_active: false })}
                          variables={{ id: iid }}
                        >
                          {({
                            loading: ingredientLoading,
                            error: ingredientError,
                            data: ingredientData,
                          }) => {
                            if (ingredientLoading) {
                              return <LoadingIndicator />;
                            }
                            if (ingredientError) {
                              return <Error message={ingredientError} />;
                            }
                            if (
                              !ingredientData.ingredient ||
                              ingredientData.ingredient.length !== 1
                            ) {
                              return (
                                <Error message="Error loading ingredient..." />
                              );
                            }
                            const ingredient = ingredientData.ingredient[0];

                            return (
                              <IngredientSummary ingredient={ingredient} />
                            );
                          }}
                        </Query>
                      )}
                    </Paper>
                  </Drawer>
                );
              }}
            </Route>
            <Paper className={classes.container} elevation={2}>
              <h3>Raw Material</h3>
              <div className={classes.tableWrapper}>
                <Table className={classes.smalltable} size="small">
                  <TableBody>
                    <TableRow key="id">
                      <TableCell>ID:</TableCell>
                      <TableCell>{material.id}</TableCell>
                    </TableRow>

                    {Object.keys(display.fields).map(name => {
                      let value = '';
                      if (display.fields[name].type === 'ingredients')
                        value = material.ingredient.name;
                      else if (display.fields[name].type === 'suppliers')
                        value = material.supplier.name;
                      else value = material[name];
                      return display.fields[name].type !== 'subform' &&
                        display.fields[name].type !== 'aggregate' &&
                        material[name] &&
                        material[name] !== '' ? (
                        <TableRow key={display.fields[name].label}>
                          <TableCell>{display.fields[name].label}:</TableCell>
                          <TableCell>{value}</TableCell>
                        </TableRow>
                      ) : null;
                    })}
                    <TableRow key="edit" className={classes.editRow}>
                      <TableCell />
                      <TableCell>
                        <Link
                          className={classes.editButton}
                          to={`/update/material/${id}`}
                        >
                          <IconButton
                            size="small"
                            aria-label="Details"
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <Fab
                component={Link}
                color="primary"
                className={classes.fabButton}
                to={`/update/material/${material.id}/`}
                aria-label="Add"
              >
                <AddIcon />
              </Fab>
            </Paper>
          </>
        );
      }}
    </Query>
  );
}

MaterialDetailsPage.defaultProps = {};

MaterialDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaterialDetailsPage);
