/**
 *
 * Checkboxes
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import * as actionTypes from '../../store/actions';
import { isChecked } from '../../store/selectors';

const styles = () => ({
  size: {
    width: 30,
    height: 30,
    fontSize: 14,
  },
  formLabel: {
    fontSize: 14,
    margin: '3px 0 3px -6px',
    '& span': { fontSize: 14 },
  },
  sizeIcon: {
    fontSize: 14,
  },
});

const FilterCheckbox = props => {
  const { value, classes, onFilterToggle, isItemChecked } = props;

  const handleCheckBoxChange = (event, v) => {
    onFilterToggle(v);
  };

  const val = value.value !== undefined ? value.value : value;
  const label = value.label !== undefined ? value.label : val;
  return (
    <FormControlLabel
      className={classes.formLabel}
      key={`${val}`}
      control={
        <Checkbox
          color="primary"
          checked={isItemChecked(val)}
          onChange={e =>
            handleCheckBoxChange(e, {
              value: val,
              label,
            })
          }
          value={{
            value: val,
            label,
          }}
          className={classes.size}
          icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
          checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
        />
      }
      label={`${label}`}
    />
  );
};

FilterCheckbox.defaultProps = {
  value: {},
};

FilterCheckbox.propTypes = {
  onFilterToggle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.object,
  isItemChecked: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    isItemChecked: value => {
      return isChecked(state, props.store, props.field, value);
    },
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onFilterToggle: value => {
      dispatch({
        type: actionTypes.TOGGLE_FILTERITEM,
        store: props.store,
        item: {
          id: props.field,
          value,
          type: props.type
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'search',
          category: props.title,
          search: value.label,
        },
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(FilterCheckbox));
