import React from 'react';
import PropTypes from 'prop-types';
import { isNumber, fixedValue } from '../../scripts/utils';

function Percentage({ value }) {
  return (
    value !== undefined &&
    isNumber(value) &&
    value > 0 && <>{fixedValue(value)}</>
  );
}

Percentage.defaultProps = {
  value: undefined,
};

Percentage.propTypes = {
  value: PropTypes.number,
};

export default Percentage;
