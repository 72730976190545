/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

function Dosage({ dosage_per_wash_weight, dosage_per_wash_volume, empty }) {
  if (dosage_per_wash_weight || dosage_per_wash_volume)
    return (
      <>
        {dosage_per_wash_weight
          ? `${dosage_per_wash_weight.toFixed(1)} g/wash`
          : `${dosage_per_wash_volume.toFixed(1)} ml/wash`}
      </>
    );
  if (empty) return <>{empty}</>;
  return null;
}
Dosage.defaultProps = {
  dosage_per_wash_weight: null,
  dosage_per_wash_volume: null,
  empty: null,
};

Dosage.propTypes = {
  dosage_per_wash_weight: PropTypes.number,
  dosage_per_wash_volume: PropTypes.number,
  empty: PropTypes.string,
};

export default Dosage;
