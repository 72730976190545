/**
 *
 * Chips
 *
 */

import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';

import * as actionTypes from '../../store/actions';
import { getSearch, getFilteredFields } from '../../store/selectors';

// Utils
const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  chipContainer: {
    margin: '10px 0',
  },
});

const FilterChips = props => {
  const { classes, onFilterToggle, onRemoveFilter, filters } = props;

  const handleDelete = (id, value) => () => {
    onFilterToggle(id, value);
  };

  const handleRemove = (id) => () => {
    onRemoveFilter(id);
  };

  return (
    <>
      <div className={classes.chipContainer}>
        {filters.map(filter => {
          if (filter.type==="date") {
            const label=`${filter.values[0]}-${filter.values[1]}`
            return (
              <Chip
                key={`${filter.id}`}
                color="primary"
                label={label}
                className={classes.chip}
                onDelete={handleRemove(filter.id)}
              />
            )
          }


          return filter.values.map(values => {
            if (!values || !values.value) {
              return null;  
            }
            const value = values.value.value
              ? values.value.value
              : values.value;
            const label = values.value.label
              ? values.value.label
              : values.value;


            return (
              values.checked && (
                <Chip
                  key={`${filter.id}-${value}`}
                  color="primary"
                  label={label}
                  className={classes.chip}
                  onDelete={handleDelete(filter.id, values.value)}
                />
              )
            );
          })}
        )}
      </div>
    </>
  );
};

FilterChips.defaultProps = {
  filters: [],
};

FilterChips.propTypes = {
  onFilterToggle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  filters: PropTypes.array,
};

const mapStateToProps = (state, props) => {
  return {
    searchValue: getSearch(state, props.store),
    filters: getFilteredFields(state, props.store),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onFilterToggle: (id, value) =>
      dispatch({
        type: actionTypes.TOGGLE_FILTERITEM,
        store: props.store,
        item: {
          id,
          value,
        },
      }),
    onRemoveFilter: value =>
      dispatch({
        type: actionTypes.REMOVE_FILTERITEM,
        store: props.store,
        item: {
          id: value,
        },
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(FilterChips));
