import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Range from '../Range';
import Price from '../Price';
import Percentage from '../Percentage';
import { sortFormulations } from '../../scripts/utils';
import Can from '../Can';

const styles = () => ({
  button: {
    margin: 'theme.spacing(1)px 0',
  },
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  overviewTable: {
    marginBottom: 50,
    width: 'auto',
    '& tbody': {
      borderTop: '1px solid #A5D8DA',
      borderBottom: '1px solid #A5D8DA',
    },
    '& td, & th': {
      borderColor: '#A5D8DA',
      border: 0,
    },
  },
  tableHead: {
    whiteSpace: 'nowrap',
  },
  headCell: {
    whiteSpace: 'nowrap',
  },
  valueCell: {
    minWidth: 200,
  },
  rowDepth3: {
    // fontStyle: 'italic',
    '& td, & th': {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  depth1: {},
  depth2: {},
  depth3: {
    paddingLeft: 10,
    fontSize: 13,
  },
  actionCell: {
    width: 50,
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const StyledTableBody = withStyles(() => ({
  root: {
    '&:first-of-type': {
      // backgroundColor: 'rgba(165, 216, 218, 0.15)',
      fontWeight: 'bold',
      '& td, & th': {
        fontWeight: 'bold',
      },
    },
    '&:nth-of-type(even)': {
      // backgroundColor: 'rgba(165, 216, 218, 0.15)',
    },
  },
}))(TableBody);

const StyledPrice = withStyles(() => ({
  textAlign: 'right',
}))(Price);

function ProductTotals(props) {
  const { classes, products } = props;

  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.overviewTable} size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.valueCell} />

            <Can
              perform="product.field.percentage_sds_totals"
              yes={() => (
                <TableCell className={classes.headCell}>SDS %</TableCell>
              )}
            />
            <Can
              perform="product.field.percentage_label"
              yes={() => (
                <TableCell className={classes.headCell}>Label %</TableCell>
              )}
            />
            <Can
              perform="product.field.percentage_frames"
              yes={() => (
                <TableCell className={classes.headCell}>Frames %</TableCell>
              )}
            />
            <Can
              perform="product.field.price_active"
              yes={() => (
                <TableCell className={classes.headCell}>Frames €/ton</TableCell>
              )}
            />
            <TableCell />
          </TableRow>
        </TableHead>
        {products.map(product => {
          const formulations = sortFormulations(product.totals);
          return (
            formulations &&
            formulations.length !== 0 &&
            formulations.map(formulas => (
              <StyledTableBody key={formulas[0].type}>
                {formulas &&
                  formulas.length !== 0 &&
                  formulas.map(formula => (
                    <TableRow
                      key={`${formula.class_name}${formula.type}${formula.depth}`}
                      className={classes[`rowDepth${formula.depth}`]}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.valueCell}
                      >
                        <div className={classes[`depth${formula.depth}`]}>
                          {formula.type}
                        </div>
                      </TableCell>
                      <Can
                        perform="product.field.percentage_sds_totals"
                        yes={() => (
                          <TableCell className={classes.headCell}>
                            {formula.type !== 'OTHER' && (
                              <div className={classes[`depth${formula.depth}`]}>
                                <Range
                                  min={formula.percentage_min_sds}
                                  max={formula.percentage_max_sds}
                                />
                              </div>
                            )}
                          </TableCell>
                        )}
                      />
                      <Can
                        perform="product.field.percentage_label"
                        yes={() => (
                          <TableCell className={classes.headCell}>
                            {formula.type !== 'OTHER' && (
                              <div className={classes[`depth${formula.depth}`]}>
                                <Range
                                  min={formula.percentage_min_label}
                                  max={formula.percentage_max_label}
                                />
                              </div>
                            )}
                          </TableCell>
                        )}
                      />
                      <Can
                        perform="product.field.percentage_frames"
                        yes={() => (
                          <TableCell className={classes.headCell}>
                            <div className={classes[`depth${formula.depth}`]}>
                              <Percentage value={formula.percentage_frames} />
                            </div>
                          </TableCell>
                        )}
                      />
                      <Can
                        perform="product.field.price_active"
                        yes={() => (
                          <TableCell className={classes.headCell}>
                            <div className={classes[`depth${formula.depth}`]}>
                              <StyledPrice
                                value={formula.price_active}
                                decimals={0}
                              />
                            </div>
                          </TableCell>
                        )}
                      />
                      <TableCell />
                    </TableRow>
                  ))}
              </StyledTableBody>
            ))
          );
        })}
      </Table>
    </div>
  );
}

ProductTotals.defaultProps = {};

ProductTotals.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProductTotals);
