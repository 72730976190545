import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  from,
} from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const makeApolloClient = (uri, wsUri, getAccessTokenSilently, getRole) => {
  // get access token

  const auth0Link = setContext(async (req, { headers }) => {
    const token = await getAccessTokenSilently();
    return {
      ...headers,
      headers: { authorization: token ? `Bearer ${token}` : null },
    };
  });

  const passLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const passHheaders = {
      'content-type': 'application/json',
      'x-hasura-admin-secret': process.env.REACT_APP_SECRET,
    };

    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          ...passHheaders,
        },
      };
    });
    return forward(operation);
  });

  const roleLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers

    const role = getRole();
    const roleHeaders = {
      'x-hasura-role': role,
    };

    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          ...roleHeaders,
        },
      };
    });
    return forward(operation);
  });

  // Create a WebSocket link:
  const wsLink = new WebSocketLink(
    new SubscriptionClient(wsUri, {
      reconnect: true,
      timeout: 30000,
      connectionParams: () => {
        // return { headers: getHeaders() };
      },
      connectionCallback: err => {
        if (err) {
          wsLink.subscriptionClient.close(false, false);
        }
      },
    }),
  );

  // for apollo client
  const httpLink = new HttpLink({
    uri,
  });

  // chose the link to use based on operation

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  // Disable cache so it reflects the updates
  // If you're just displaying data and not mutating them you can remove it
  // to increase performances
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  };

  const authLink = process.env.REACT_APP_SECRET ? passLink : auth0Link;
  const client = new ApolloClient({
    link: from([authLink, roleLink, link]),
    cache: new InMemoryCache({
      addTypename: true,
    }),
    defaultOptions,
  });

  return client;
};

export default makeApolloClient;
