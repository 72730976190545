const json = {
  formula: {
    name: 'formula',
    fields: {
      ranking: {
        label: 'Ranking',
        default: '',
        type: 'integer',
        width: {
          sm: 2,
        },
      },
      ingredient: {
        label: '',
        default: '',
        type: 'ingredients',
        width: {
          sm: 10,
        },
      },
      percentage_min_sds: {
        label: 'SDS min %',
        default: '',
        type: 'float',
      },
      percentage_max_sds: {
        label: 'SDS max %',
        default: '',
        type: 'float',
      },
      percentage_min_label: {
        label: 'Label min %',
        default: '',
        type: 'float',
      },
      percentage_max_label: {
        label: 'Label max %',
        default: '',
        type: 'float',
      },
      percentage_frames: {
        label: 'FRAMES % estimate',
        default: '',
        type: 'float',
      },
    },
  },
};

export default json;
