import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dosage from '../Dosage';
import Fixed from '../Fixed';
import Price from '../Price';

const StyledPrice = withStyles(() => ({
  textAlign: 'right',
}))(Price);

function Item(props) {
  const { value, options } = props;

  return (
    <>
      {(renderAs => {
        switch (renderAs) {
          case 'price':
            return (
              <StyledPrice
                value={value}
                empty="-"
                decimals={
                  options && options.decimals !== undefined
                    ? options.decimals
                    : 2
                }
              />
            );
          case 'fixed':
            return (
              <Fixed
                value={value}
                decimals={
                  options && options.decimals !== undefined
                    ? options.decimals
                    : 1
                }
                empty="-"
              />
            );

          case 'dosage_per_wash_weight':
            return <Dosage dosage_per_wash_weight={value} empty="-" />;
          case 'dosage_per_wash_volume':
            return <Dosage dosage_per_wash_volume={value} empty="-" />;

          default:
            return value || '-';
        }
      })(options && options.renderAs ? options.renderAs : 'default')}
    </>
  );
}

Item.defaultProps = {
  value: null,
  options: {},
};

Item.propTypes = {
  value: PropTypes.any,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Item;
