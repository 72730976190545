import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import AsyncCreatableSelect from 'react-select/async-creatable';
// import client from '../../../scripts/apollo';
import { useApolloClient, useMutation } from '@apollo/client';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#000' : '#000',
    backgroundColor: state.isSelected ? 'rgba(165, 216, 218, 0.15)' : 'inherit',
    '&:hover': {
      // Overwrittes the different states of border
      backgroundColor: 'rgba(165, 216, 218, 0.25)',
    },
  }),
  menu: provided => ({
    ...provided,
    fontSize: 14,
  }),
  menuList: provided => ({
    ...provided,
    fontSize: 14,
  }),

  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: '#A5D8DA',
  }),
  clearIndicator: provided => ({
    ...provided,
    color: '#27827B',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#27827B',
  }),
  control: provided => ({
    ...provided,
    borderColor: '#A5D8DA',
    fontSize: 14,
    '&:hover': {
      // Overwrittes the different states of border
      borderColor: '#A5D8DA',
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

function GraphCreate(props) {
  const { mutate, query, value } = props;
  const [addOption] = useMutation(mutate);
  const client = useApolloClient();

  const getValue = label =>
    !label ? null : label.toLowerCase().replace(/\W/g, '');

  const createOption = useCallback(
    label => ({
      label,
      value: getValue(label),
    }),
    [],
  );

  const [currentValue, setCurrentValue] = useState(createOption(value));
  console.log('GraphCreate', value, currentValue);

  useEffect(() => {
    setCurrentValue(createOption(value));
    return () => {};
  }, [createOption, value]);

  const handleCreate = inputValue => {
    console.group('Option created');
    console.log('Wait a moment...', props);
    addOption({ variables: { name: `${inputValue}` } });
    setCurrentValue(createOption(inputValue));
  };

  const promiseOptions = inputValue =>
    client
      .query({
        query,
        variables: {
          name: `%${inputValue}%`,
        },
      })
      .then(result => {
        return result.data.options.map(option => createOption(option.value));
      })
      .then(values =>
        values.filter(i =>
          i.label.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      )
      .catch(e => {
        // GraphQL errors can be extracted here
        if (e.graphQLErrors) {
          // reduce to get message
          console.log('Error', e, e.graphQLErrors);
        }
      });

  return (
    <AsyncCreatableSelect
      // onChange={handleChange}
      // onBlur={handleBlur}
      cacheOptions
      defaultOptions
      loadOptions={inputValue => promiseOptions(inputValue)}
      onCreateOption={inputValue => handleCreate(inputValue)}
      styles={customStyles}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
      value={currentValue}
    />
  );
}

GraphCreate.defaultProps = {
  value: {},
};

GraphCreate.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string,
  query: PropTypes.object.isRequired,
  mutate: PropTypes.object.isRequired,
};

export default GraphCreate;
