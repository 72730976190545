import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import example from '../../assets/images/Comparison screen - open access version.jpg'

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  image: {
    width: '100%'
  }
});


function ProductCompareDemoPage(props) {
  const {
    classes
  } = props;
  return (
      <>
        <Helmet>
          <title>Ingredient Demo Page</title>
          <meta name="description" content="" />
        </Helmet>
        
        <Paper className={classes.container} elevation={2}>
          <img className={classes.image} src={example} alt="ingredient" />
        </Paper>
      </>
  );
}

ProductCompareDemoPage.defaultProps = {};

ProductCompareDemoPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCompareDemoPage);
