import * as actionTypes from './actions';

const initialState = {
  user: {
    loggedIn: false,
    name: 'Test User',
    role: 'admin',
    credits: 100,
  },
  ingredients: {
    search: undefined,
    items: [],
    favorites: [],
    refetch: false,
  },
  products: {
    search: undefined,
    items: [],
    favorites: [],
    refetch: false,
  },
  materials: {
    search: undefined,
    items: [],
    favorites: [],
    refetch: false,
  },
  files: {
    search: undefined,
    items: [],
    favorites: [],
    refetch: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_FAVORITE: {
      const { favorites } = state[action.store];
      return {
        ...state,
        user: { ...state.user },
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        [action.store]: {
          ...state[action.store],
          favorites: [...favorites, action.item],
        },
      };
    }
    case actionTypes.REMOVE_FAVORITE: {
      const { favorites } = state[action.store];
      const copy = favorites.filter(p => p.id !== action.item.id);
      return {
        ...state,
        user: { ...state.user },
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        [action.store]: {
          ...state[action.store],
          favorites: [...copy],
        },
      };
    }
    case actionTypes.TOGGLE_FAVORITE: {
      const { favorites } = state[action.store];
      const copy = favorites.filter(p => p.id !== action.item.id);
      if (copy.length === favorites.length) {
        copy.push(action.item);
      }
      return {
        ...state,
        user: { ...state.user },
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        [action.store]: {
          ...state[action.store],
          favorites: [...copy],
        },
      };
    }

    case actionTypes.TOGGLE_FILTERITEM: {
      const filteredItems =
        state[action.store].items.filter(i => i.id !== action.item.id) || [];

      const filteredItem = state[action.store].items.find(
        i => i.id === action.item.id,
      ) || {
        id: action.item.id,
        type: action.item.type,
        values: [{ value: action.item.value, checked: false }],
      };
      let values = [];
      let value = '';

      if (action.item.value.value) {
        values =
          filteredItem.values.filter(
            v => v.value.value !== action.item.value.value,
          ) || [];

        value = filteredItem.values.find(
          v => v.value.value === action.item.value.value,
        ) || { value: action.item.value, checked: false };
      } else {
        values =
          filteredItem.values.filter(v => v.value !== action.item.value) || [];

        value = filteredItem.values.find(
          v => v.value === action.item.value,
        ) || { value: action.item.value, checked: false };
      }

      values.push({
        value: action.item.value,
        checked: !value.checked,
      });

      filteredItem.values = values;
      filteredItems.push(filteredItem);

      const newState = {
        ...state,
        user: { ...state.user },
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        [action.store]: {
          ...state[action.store],
          items: filteredItems,
        },
      };

      return newState;
    }


    case actionTypes.SET_FILTERITEM: {
      const filteredItems =
        state[action.store].items.filter(i => i.id !== action.item.id) || [];

      const filteredItem = 
        {
          id: action.item.id,
          type: action.item.type,
          values: action.item.value
        };

      filteredItems.push(filteredItem);

      const newState = {
        ...state,
        user: { ...state.user },
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        [action.store]: {
          ...state[action.store],
          items: filteredItems,
        },
      };

      return newState;
    }

    case actionTypes.REMOVE_FILTERITEM: {
      const filteredItems =
        state[action.store].items.filter(i => i.id !== action.item.id) || [];

      const newState = {
        ...state,
        user: { ...state.user },
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        [action.store]: {
          ...state[action.store],
          items: filteredItems,
        },
      };

      return newState;
    }

    case actionTypes.SET_FILTERSEARCH: {
      const newState = {
        ...state,
        user: { ...state.user },
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        [action.store]: {
          ...state[action.store],
          search: action.search,
        },
      };

      return newState;
    }

    case actionTypes.CLEAR_FILTER: {
      const newState = {
        ...state,
        user: { ...state.user },
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        [action.store]: {
          ...state[action.store],
          search: undefined,
          items: [],
          refetch: true,
        },
      };

      return newState;
    }

    case actionTypes.CLEAR_REFETCH: {
      const newState = {
        ...state,
        user: { ...state.user },
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        [action.store]: {
          ...state[action.store],
          refetch: false,
        },
      };

      return newState;
    }

    case actionTypes.LOGIN: {
      const newState = {
        ...state,
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        user: { ...state.user, ...action },
      };

      return newState;
    }

    case actionTypes.DECREASE_CREDITS: {
      let { credits } = state.user;
      if (credits > 0) {
        credits -= action.credits;
      }
      const newState = {
        ...state,
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        user: { ...state.user, credits },
      };

      return newState;
    }

    case actionTypes.SET_USER: {
      const newState = {
        ...state,
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        user: { ...state.user, ...action.user },
      };

      return newState;
    }

    case actionTypes.SET_ROLE: {
      const newState = {
        ...state,
        ingredients: { ...state.ingredients },
        products: { ...state.products },
        materials: { ...state.materials },
        user: { ...state.user, role: action.role },
      };

      return newState;
    }

    default:
      return state;
  }
};

export default reducer;
