/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import { Auth0Context } from '../../hooks/AuthContext';

import LoadingIndicator from '../LoadingIndicator';
import * as actionTypes from '../../store/actions';

import Error from '../Error';
import { BULK_INSERT_FILES } from '../../queries';
import { UPLOAD_API } from '../../config/constants';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: '0 0 30px 0',
    flex: '0 0 100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
  },
  fieldset: {
    width: '100%',
  },
  formHelperText: {
    fontSize: '1em',
    color: '#333',
    lineHeight: '1.4',
  },
  button: {
    margin: '15px 0 5px 0',
  },
  buttons: {
    margin: -5,
  },
  error: {
    color: 'red',
    margin: '10px 0',
    fontSize: 16,
  },
  textarea: {
    fontSize: '18px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  legend: {
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    marginBottom: '5px',
  },
});

const UploadForm = props => {
  const { classes, onUploaded, onClearFilter } = props;
  const [bulkInsertFiles, { loading, error, data }] = useMutation(
    BULK_INSERT_FILES,
  );
  const { getAccessTokenSilently } = useContext(Auth0Context);

  useEffect(() => {
    if (!loading && !error && data) {
      onUploaded(data);
      onClearFilter();
    }
    return () => {};
  }, [data, error, loading, onClearFilter, onUploaded]);

  const uploadFile = async data => {
    const { description, files } = data;

    const formData = new FormData();

    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    const token = await getAccessTokenSilently();
    const response = await axios.post(`${UPLOAD_API}storage/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-path': '/products/packshots/',
        Authorization: `Bearer ${token}`,
      },
    });
    const insertedFiles = response.data;
    const variables = {
      files: [],
    };

    insertedFiles.forEach(insertedFile => {
      const file = {
        description,
        name: insertedFile.originalname,
        type: insertedFile.mimetype,
        path: insertedFile.key,
        extension: insertedFile.extension,
      };
      variables.files.push(file);
    });

    bulkInsertFiles({ variables });
  };

  if (loading) return <LoadingIndicator />;
  if (error) return <Error message={error.message} />;

  return (
    <Formik
      initialValues={{
        description: '',
        files: [],
      }}
      onSubmit={(values, { setSubmitting }) => {
        uploadFile(values);
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        files: Yup.mixed().required('File is required'),
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <>
            {data && (
              <h3>
                You have imported or updated {data.file.affected_rows} files
              </h3>
            )}
            <form
              onSubmit={e => {
                e.preventDefault();
                return false;
              }}
            >
              <FormControl className={classes.formControl}>
                <TextField
                  id="description"
                  label="Description"
                  value={values.name}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  margin="dense"
                  fullWidth
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  type="file"
                  id="files"
                  inputProps={{ multiple: true }}
                  onChange={event => {
                    const { files } = event.target;
                    setFieldValue('files', files);
                  }}
                  error={touched.description && Boolean(errors.description)}
                  margin="dense"
                  fullWidth
                />
              </FormControl>

              <div className={classes.buttons}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Upload File(s)
                </Button>
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

UploadForm.defaultProps = {
  onUploaded: () => {},
};

UploadForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onUploaded: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    onClearFilter: () =>
      dispatch({
        type: actionTypes.CLEAR_FILTER,
        store: 'files',
      }),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(UploadForm));
