/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import { withFormik } from 'formik';
import { Query } from '@apollo/client/react/components'
import { graphql } from '@apollo/client/react/hoc';
import flowright from 'lodash.flowright';
// import Yup from 'yup';
import * as Yup from 'yup';
import history from '../../scripts/history';
import DynamicForm from '../../components/DynamicForm';
import Error from '../../components/Error';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Auth0Context } from '../../hooks/AuthContext';

// Layout
import layout from '../../config/formula-layout';

import {
  GET_PRODUCT,
  GET_FORMULA,
  CREATE_FORMULA,
  UPDATE_FORMULA,
  DELETE_FORMULA,
} from '../../queries';

const display = layout.formula;

function LoadingForm(props) {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;

  const {
    id,
    pid,
    getFormula,
    deleteFormula,
    setSubmitting,
    setErrors,
  } = props;
  const deleteValues = {
    id,
  };
  const handleDelete = () => {
    deleteFormula({ variables: deleteValues })
      .then(data => {
        const formula = data.data.formula.formula[0];
        setSubmitting(false);
        history.push(`/product/${formula.product_id}`);
      })
      .catch(e => {
        const errors = e.graphQLErrors || [];
        const error = errors.map(err => err.message).join('\n');
        setSubmitting(false);
        setErrors({ submitForm: error });
      });
  };

  return (
    <Query
      query={GET_PRODUCT({
        with_price_active:
          (user && user.access && user.access.product_price_active) || false,
        with_percentage:
          (user && user.access && user.access.percentage) || false,
      })}
      variables={{
        ids: [pid],
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingIndicator />;
        }

        if (error) {
          return <Error message={error.message} />;
        }

        if (!data.product || data.product.length !== 1) {
          // history.replace(`/products`);
          return <Error message="Error loading product.." />;
        }

        if (getFormula.loading) {
          return <LoadingIndicator />;
        }

        const formula =
          (getFormula && getFormula.formula && getFormula.formula[0]) || false;

        if (id && !formula) {
          // history.replace(`/product/${pid}`);
          return <Error message="Error loading formula.." />;
        }

        return (
          <DynamicForm handleDelete={id ? handleDelete : null} {...props} />
        );
      }}
    </Query>
  );
}

const FormikWithGraphQL = flowright(
  graphql(GET_FORMULA({ with_percentage: true, with_price_active: true }), {
    name: 'getFormula',
    options: props => ({
      variables: {
        id: props.id,
        product_id: props.pid,
      },
    }),
  }),
  graphql(UPDATE_FORMULA, { name: 'updateFormula' }),
  graphql(CREATE_FORMULA, { name: 'createFormula' }),
  graphql(DELETE_FORMULA, { name: 'deleteFormula' }),

  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      /*
      ranking: Yup.number()
        .required('Ranking is required')
        .typeError('Ranking must be a number'),
        */
      ingredient: Yup.object()
        .typeError('Select ingredient')
        .shape({
          label: Yup.string()
            .min(1, 'Ingredient is required')
            .required('Ingredient is required')
            .typeError('Select ingredient'),
        }),
    }),

    mapPropsToValues: props => {
      const { getFormula } = props;
      const formula =
        (getFormula && getFormula.formula && getFormula.formula[0]) || {};
      return formula;
    },
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { createFormula, updateFormula, pid } = props;
      const isUpdate = !!(values.id && values.id !== '');
      const handleCreateOrUpdate = isUpdate ? updateFormula : createFormula;
      const updateValues = { ...values };
      delete updateValues.action;
      delete updateValues.product;
      delete updateValues.__typename;
      Object.keys(display.fields).forEach(name => {
        const input = display.fields[name];
        const { type } = input;
        if (type === 'float') {
          updateValues[name] = parseFloat(values[name]);
        }
        if (type === 'integer') {
          updateValues[name] = parseInt(values[name], 10);
        }
      });
      // delete updateValues.product;
      if (values.ingredient) {
        updateValues.ingredient = values.ingredient.value;
      }
      updateValues.product_id = pid;
      handleCreateOrUpdate({ variables: updateValues })
        .then(data => {
          const formula = data.data.formula.formula[0];
          if (!formula || !formula.id || formula.id === '') {
            setSubmitting(false);
          }
          setSubmitting(false);
          history.push(`/product/${formula.product_id}`);
        })
        .catch(e => {
          const errors = e.graphQLErrors || [];
          const error = errors.map(err => err.message).join('\n');
          setSubmitting(false);
          setErrors({ submitForm: error });
        });
    },
    displayName: 'Form',
  }),
)(LoadingForm);

FormikWithGraphQL.defaultProps = {};

FormikWithGraphQL.propTypes = {};

function FormulaForm(props) {
  const {
    match: {
      params: { id, pid },
    },
  } = props;

  return <FormikWithGraphQL {...props} display={display} id={id} pid={pid} />;
}

FormulaForm.defaultProps = {};

FormulaForm.propTypes = {};

export default FormulaForm;
