/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';

// import Yup from 'yup';
import * as Yup from 'yup';
import { graphql } from '@apollo/client/react/hoc';
import flowright from 'lodash.flowright';
import history from '../../scripts/history';
import DynamicForm from '../../components/DynamicForm';
import LoadingIndicator from '../../components/LoadingIndicator';

// Layout
import layout from '../../config/material-layout.json';

import {
  CREATE_MATERIAL,
  UPDATE_MATERIAL,
  GET_MATERIAL,
  DELETE_MATERIAL,
} from '../../queries';

const display = layout.material;

function LoadingForm(props) {
  const { id, getMaterial, deleteMaterial, setSubmitting, setErrors } = props;
  if (getMaterial.loading) {
    return <LoadingIndicator />;
  }
  const deleteValues = {
    id,
  };
  const handleDelete = () => {
    deleteMaterial({ variables: deleteValues })
      .then(() => {
        setSubmitting(false);
        history.push(`/ingredients`);
      })
      .catch(e => {
        const errors = e.graphQLErrors || [];
        const error = errors.map(err => err.message).join('\n');
        setSubmitting(false);
        setErrors({ submitForm: error });
      });
  };

  return <DynamicForm handleDelete={id ? handleDelete : null} {...props} />;
}

LoadingForm.defaultProps = {
  id: null,
};

LoadingForm.propTypes = {
  getMaterial: PropTypes.object.isRequired,
  id: PropTypes.string,
  deleteMaterial: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
};

const FormikWithGraphQL = flowright(
  graphql(GET_MATERIAL, {
    name: 'getMaterial',
    options: props => ({
      variables: {
        id: props.id,
      },
    }),
  }),
  graphql(UPDATE_MATERIAL, { name: 'updateMaterial' }),
  graphql(CREATE_MATERIAL, { name: 'createMaterial' }),
  graphql(DELETE_MATERIAL, { name: 'deleteMaterial' }),
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      trade_name: Yup.string()
        .max(128, 'No more then 128')
        .required('Tradename is required'),
      ingredient: Yup.object()
        .typeError('Select ingredient')
        .shape({
          label: Yup.string()
            .min(1, 'Ingredient is required')
            .required('Ingredient is required')
            .typeError('Select ingredient'),
        }),
      supplier: Yup.object()
        .typeError('Select supplier')
        .shape({
          label: Yup.string()
            .min(1, 'Supplier is required')
            .required('Supplier is required')
            .typeError('Select supplier'),
        }),
    }),

    mapPropsToValues: props => {
      const material =
        (props.getMaterial &&
          props.getMaterial.material &&
          props.getMaterial.material[0]) ||
        {};
      return material;
    },
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { createMaterial, updateMaterial } = props;

      const isUpdate = !!(values.id && values.id !== '');
      const handleCreateOrUpdate = isUpdate ? updateMaterial : createMaterial;
      const updateValues = { ...values };
      delete updateValues.action;
      delete updateValues.formulas;
      delete updateValues.__typename;

      Object.keys(display.fields).forEach(name => {
        const input = display.fields[name];
        const { type } = input;
        if (type === 'float') {
          updateValues[name] = parseFloat(values[name]);
        }
      });

      if (values.ingredient) {
        updateValues.ingredient = values.ingredient.value;
      }

      if (values.supplier) {
        updateValues.supplier = values.supplier.value;
      }

      handleCreateOrUpdate({ variables: updateValues })
        .then(data => {
          const material = data.data.material.material[0];
          if (!material || !material.id || material.id === '') {
            console.log(
              'Error updateMaterial material not returned from graph',
              updateMaterial,
            );
          }
          history.push(`/material/${material.id}`);
        })
        .catch(e => {
          const errors = e.graphQLErrors || [];
          const error = errors.map(err => err.message).join('\n');
          setSubmitting(false);
          setErrors({ submitForm: error });
        });
    },
    displayName: 'Form',
  }),
)(LoadingForm);

FormikWithGraphQL.defaultProps = {};

FormikWithGraphQL.propTypes = {};

function MaterialForm(props) {
  const {
    match: {
      params: { id },
    },
  } = props;

  return <FormikWithGraphQL {...props} display={display} id={id} />;
}

MaterialForm.defaultProps = {};

MaterialForm.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MaterialForm;
