/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * IngredientsPage
 *
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Query } from '@apollo/client/react/components';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Auth0Context } from '../../hooks/AuthContext';
import Search from '../../components/Search';
import Listing from './listing';
import history from '../../scripts/history';
import IngredientSummary from '../../components/IngredientSummary';

import { GET_INGREDIENT, GET_INGREDIENT_COUNTS } from '../../queries';

import layout from '../../config/ingredient-layout.json';
import Can from '../../components/Can';

const display = layout.ingredient;

// Utils
const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  filterContainer: {
    padding: theme.spacing(2),
    position: 'relative',
    width: 'auto',
    minWidth: 320,
    maxWidth: 500,
    boxShadow: 'none',
  },
  rightDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  leftDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  reloadContainer: {
    display: 'flex',
    margin: `${theme.spacing(1)}px 0 0 0 `,
  },
  searchContainer: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
  },
  fieldset: {
    width: '100%',
  },
  nonCollapsableFormControl: {
    flex: 1,
  },
  formControl: {
    flex: '0 0 100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    border: '1px solid #A5D8DA',
    margin: '15px 15px 20px 15px',
    // boxShadow: '0 3px 5px 0 rgba(0,0,0,.15)',
  },
  inputText: {
    marginLeft: 8,
    flex: 1,
    width: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
  },
  actions: {
    alignItems: 'flex-end',
    margin: 'auto 0 0 0',
    // display: 'flex',
    // justifyContent: 'space-between',
  },
  inputWrapper: {},
  buttons: {
    margin: '20px -5px',
  },
  button: {
    margin: 5,
  },
  clearButton: {
    margin: '-10px 0 30px',
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    bottom: 20,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
});

const IngredientsPage = props => {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;

  const { classes } = props;

  const handleCloseDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    history.goBack();
  };

  return (
    <>
      <Helmet>
        <title>Ingredients Page</title>
        <meta name="description" content="Ingredients Page" />
      </Helmet>
      <Route path="/ingredients/details/:id">
        {({ match }) => {
          const isMatch = match && match.params;
          const id = match && match.params && match.params.id;

          return (
            <Drawer
              open={!!isMatch}
              width="100%"
              anchor="left"
              onClose={e => handleCloseDrawer(e)}
            >
              <Paper className={classes.filterContainer} elevation={2}>
                <div className={classes.leftDrawerHeader}>
                  <Link
                    to="/ingredients"
                    onClick={e => handleCloseDrawer(e)}
                    color="primary"
                  >
                    <h3>
                      Close
                      <ChevronRightIcon />
                    </h3>
                  </Link>
                </div>
                <h3>Ingredient</h3>
                {isMatch && id && (
                  <Query
                    query={GET_INGREDIENT({
                      with_price_active:
                        (user &&
                          user.access &&
                          user.access.ingredient_price_active) ||
                        false,
                    })}
                    variables={{
                      id,
                    }}
                  >
                    {({ loading, error, data }) => {
                      if (loading) {
                        return null;
                      }
                      if (error) {
                        return `Error: ${error}`;
                      }
                      const ingredient = data.ingredient[0];
                      return <IngredientSummary ingredient={ingredient} />;
                    }}
                  </Query>
                )}
              </Paper>
            </Drawer>
          );
        }}
      </Route>

      <Paper className={classes.container} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <h3>Ingredients</h3>
              <Search
                store="ingredients"
                query={GET_INGREDIENT_COUNTS}
                display={display}
                filterPath="/ingredients/filter"
              />
              <Listing offset={0} user={user} />
            </Grid>
          </Grid>
        </Grid>
        <Can
          perform="ingredient:add"
          yes={() => (
            <Fab
              component={Link}
              color="primary"
              className={classes.fabButton}
              to="/update/ingredient/"
              aria-label="Add ingredient"
            >
              <AddIcon />
            </Fab>
          )}
        />
      </Paper>
    </>
  );
};

IngredientsPage.defaultProps = {};

IngredientsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IngredientsPage);
