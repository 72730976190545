import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Auth0Context } from '../../hooks/AuthContext';
import logo from './logo.png';
import Can from '../Can';

const useStyles = makeStyles(theme => ({
  appBar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  toolBar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttons: {
    margin: `0 0 0 auto`,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  button: {
    margin: '0 5px',
    display: 'block',
    flexDirection: 'row',
  },
  logo: {
    maxHeight: '50px',
  },
}));

const menuItems = [
  {
    title: 'Products',
    perform: 'product:list',
    to: '/products',
  },
  {
    title: 'Ingredients',
    perform: 'ingredient:list',
    to: '/ingredients',
  },
  {
    title: 'Raw Materials',
    perform: 'material:list',
    to: '/materials',
  },
  {
    title: 'Import',
    perform: 'import:page',
    to: '/import',
  },
];

function Header() {
  const { isAuthenticated, loginWithRedirect, logout } = useContext(
    Auth0Context,
  );
  const classes = useStyles();
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const handleClickMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  const logoutWithRedirect = () => {
    logout({
      returnTo: window.location.origin,
    });
  };
  return (
    <>
      <AppBar position="static" color="inherit" className={classes.appBar}>
        <Container maxWidth="xl">
          <Toolbar className={classes.toolBar}>
            <Link to="/">
              <img src={logo} className={classes.logo} alt="logo" />
            </Link>
            <div className={classes.buttons}>
              {!process.env.REACT_APP_SECRET && !isAuthenticated && (
                <Button color="primary" onClick={() => loginWithRedirect({})}>
                  Login
                </Button>
              )}
              {(process.env.REACT_APP_SECRET || isAuthenticated) && (
                <>
                  {!process.env.REACT_APP_SECRET && (
                    <Button
                      color="primary"
                      type="button"
                      className={classes.button}
                      onClick={() => logoutWithRedirect()}
                    >
                      Logout
                    </Button>
                  )}
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClickMenu}
                  >
                    Menu
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorMenu}
                    keepMounted
                    open={Boolean(anchorMenu)}
                    onClose={handleCloseMenu}
                  >
                    <div>
                      {menuItems.map(menuItem => (
                        <Can
                          key={menuItem.to}
                          perform={menuItem.perform}
                          yes={() => (
                            <MenuItem
                              component={Link}
                              type="button"
                              color="primary"
                              to={menuItem.to}
                              className={classes.button}
                              onClick={handleCloseMenu}
                            >
                              {menuItem.title}
                            </MenuItem>
                          )}
                        />
                      ))}
                    </div>
                  </Menu>
                </>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;
