import rules from './rules';

export const getFavorites = (store, storeName) => store[storeName].favorites;
export const getFilter = (store, storeName) => store[storeName];
export const getUserStore = store => store.user;

export const isFavorite = (store, storeName, item) => {
  const favorites = getFavorites(store, storeName);
  const isFavourite = favorites.findIndex(p => p.id === item.id) !== -1;
  return isFavourite;
};

export const isChecked = (store, storeName, id, value) => {
  const filter = getFilter(store, storeName);
  const item = filter.items.find(i => i.id === id);
  return item === undefined
    ? false
    : item.values.find(
        v =>
          (v.value.value ? v.value.value === value : v.value === value) &&
          v.checked,
      ) !== undefined;
};

export const isFieldChecked = (store, storeName, id) => {
  const filter = getFilter(store, storeName);
  const item = filter.items.find(i => i.id === id);
  return item === undefined
    ? false
    : item.values.find(v => v.checked) !== undefined;
};

export const getSearch = (store, storeName) => {
  const filter = getFilter(store, storeName);
  return filter.search;
};


export const getValues = (store, storeName) => {
  const filter = getFilter(store, storeName);
  return filter.items;
};

export const getValue = (store, storeName, id) => {
  const filter = getFilter(store, storeName);
  const item = filter.items.find(i => i.id === id);
  return item ? item.values : undefined;
};


export const getRefetch = (store, storeName) => {
  const filter = getFilter(store, storeName);
  return filter.refetch;
};

export const getFilteredFields = (store, storeName) => {
  const filter = getFilter(store, storeName);
  const fields = filter.items.filter(item =>
    item.values.filter(v => v.checked),
  );

  return fields;
};

const permission = (role, action, data = undefined) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const check = (store, role, action, data) => {
  return permission(role, action, data);
};

export const getAccess = role => {
  const permissions = rules[role];

  if (!permissions) {
    // role is not present in the rules
    return {};
  }

  return {
    ingredient_price_active: permission(role, 'ingredient.field.price_active'),
    product_price_active: permission(role, 'product.field.price_active'),
    percentage: permission(role, 'product.field.percentage'),
    is_admin: role === 'admin',
  };
};

export const filter = (role, options, field, type) => {
  if (role !== 'user') {
    return options;
  }
  switch (field) {
    case 'category':
      return [
        'Laundry',
        'Dish',
        'Hard Surface',
        'Bleach'
      ];

    case 'subcategory':
      return [
        'Fabric washing',
        'Fabric conditioning',
        'Additives',
        'Automatic dish washing',
        'Hand dish washing',
        'Bathroom cleaning',
        'Kitchen cleaning',
        'All purpose cleaning',
        'Floor cleaning',
        'Glass cleaning'
      ];
    default: 
      return options;
  }
};
