import deburr from 'lodash.deburr';

/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */

export const fixedValue = n => {
  let f = n;
  if (n > 10) f = Math.round(n);
  else if (n >= 1) f = n.toFixed(1);
  else if (n >= 0.1) f = n.toFixed(2);
  else if (n >= 0.01) f = n.toFixed(3);
  else f = n.toFixed(4);
  return f;
  // return parseFloat(f);
};

export const isNumber = n => !isNaN(parseFloat(n)) && isFinite(n);

export const getFilter = (type, search, items, fields) => {
  let ors = [];
  const ands = [];
  const filters = [];
  let filter;

  items.forEach(item => {
    let ids, values;

    const field = fields[item.id];

    if (field.type==="date") {
      ids = [];
      values = item.values;
    } else {
      const filtered = item.values.filter(v => v.checked);
      const valueObjects = filtered.map(v => v.value);
      if (valueObjects.length <= 0) {
        return;
      }
      ids = valueObjects.map(v => (v ? v.value : null)).filter(Boolean);
      values = valueObjects.map(v => (v ? v.label : null)).filter(Boolean);  
    }

    if (item.id === 'ingredient') {
      if (ids.length > 0) {
        if (type === 'product') {
          ids.map(id => {
            ands.push({ formulas: { ingredient: { id: { _in: id } } } });
            return true;
          });
          // ands.push(_ands);
          /*
          ands.push({
            formulas: { ingredient: { id: { _in: ids } } },
          });
          */
        }
        if (type === 'ingredient') {
          ands.push({
            id: { _in: ids },
          });
        }
      }
    } else if (item.id === 'type') {
      if (type === 'product') {
        ands.push({
          formulas: { ingredient: { type: { _in: values } } },
        });
      }
      if (type === 'ingredient') {
        ands.push({
          type: { _in: values },
        });
      }
    } else if (item.id === 'supplier') {
      if (type === 'material') {
        ands.push({
          supplier: { name: { _in: values } },
        });
      }
    } else if (field.type==="date") {
      ands.push({[item.id]: { _gte: `${values[0]}-01-01`, _lte: `${values[1]}-12-31`}})
    } else {
      ands.push({
        [item.id]: { _in: values },
      });
    }
  });

  if (search !== undefined && search !== '') {
    ors = Object.keys(fields)
      .filter(
        field =>
          fields[field].type === 'select' || fields[field].type === 'string',
      )
      .map(field => ({
        [field]: { _ilike: `%${search}%` },
      }));
    if (type === 'product') {
      ors.push({
        formulas: {
          ingredient: { name: { _ilike: `%${search}%` } },
        },
      });
      const id = parseInt(search, 10);
      if (Number.isInteger(id)) {
        ors.push({
          id: { _eq: id },
        });
      }
    }
    if (type === 'material') {
      ors.push({
        supplier: { name: { _ilike: `%${search}%` } },
      });
    }
  }

  if (ands.length > 0) {
    filters.push({ _and: ands });
  }
  if (ors.length > 0) {
    filters.push({ _or: ors });
  }
  if (filters.length > 0) {
    filter = { _and: filters };
    // filter = JSON.stringify(filter);
    // filter = filter.replace(/"([^(")"]+)":/g, '$1:');
  }

  return filter;
};

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

export const sortFormulations = formulas => {
  const maps = groupBy(formulas, 'class_name');
  const keys = Object.keys(maps).map(id => maps[id]);

  keys.sort((a, b) => {
    const maxA = a.reduce(
      (max, x) => (x.percentage_frames > max ? x.percentage_frames : max),
      0,
    );
    const maxB = b.reduce(
      (max, x) => (x.percentage_frames > max ? x.percentage_frames : max),
      0,
    );

    const sortA = a.reduce(
      (min, x) => (x.sort_order > min ? x.sort_order : min),
      0,
    );
    const sortB = b.reduce(
      (min, x) => (x.sort_order > min ? x.sort_order : min),
      0,
    );

    if (sortA < sortB) {
      return -1;
    }
    if (sortA > sortB) {
      return 1;
    }

    if (maxA < maxB) {
      return 1;
    }
    if (maxA > maxB) {
      return -1;
    }
    return 0;
  });

  keys.forEach(map =>
    map.sort((a, b) => {
      if (a.depth < b.depth) {
        return -1;
      }
      if (a.depth > b.depth) {
        return 1;
      }
      if (a.sort_order < b.sort_order) {
        return -1;
      }
      if (a.sort_order > b.sort_order) {
        return 1;
      }

      if (a.percentage_frames < b.percentage_frames) {
        return 1;
      }
      if (a.percentage_frames > b.percentage_frames) {
        return -1;
      }
      return 0;
    }),
  );

  formulas.reduce(
    (acc, item) => ({
      ...acc,
      [item.depth]: {
        ...acc[item.depth],
        [item.class_name]: [
          ...((acc[item.depth] && acc[item.depth][item.class_name]) || []),
          item,
        ],
      },
    }),
    {},
  );
  return keys;
};

export const normalizeString = name => {
  let normalized = deburr(name);
  normalized = normalized.toUpperCase();
  normalized = normalized.replace(/[^A-Z0-9]+/gi, '');
  return normalized;
};

export const getNonEmptyFields = (fields, products) => {
  const nonEmptyProducts = products.map(obj => {
    const newObject = Object.keys(obj).reduce((acc, key) => {
      const _acc = acc;
      if (obj[key] !== undefined && obj[key] !== '' && obj[key] !== null)
        _acc[key] = obj[key];
      return _acc;
    }, {});
    return newObject;
  });

  const uniqueKeys = Object.keys(Object.assign({}, ...nonEmptyProducts));
  const nonEmptyFields = Object.keys(fields).reduce((acc, key) => {
    const _acc = acc;
    if (uniqueKeys.findIndex(k => k === key) !== -1) _acc[key] = fields[key];
    return _acc;
  }, {});
  return nonEmptyFields;
};

export const getFormulas = products => {
  const results = [];

  products.forEach(product => {
    const formulas = product.formulas.filter(
      formula => formula.ingredient.name !== formula.ingredient.type,
    );

    const totals = formulas.reduce((acc, formula) => {
      const _acc = acc;
      _acc[formula.ingredient.name] = {
        ...formula,
        ranking: _acc[formula.ingredient.name]
          ? Math.min(_acc[formula.ingredient.name].ranking, formula.ranking)
          : formula.ranking,
      };
      return _acc;
    }, {});

    Object.values(totals).forEach(formula => {
      if (results[formula.ingredient.name]) {
        results[formula.ingredient.name].ranking += formula.ranking;
        if (results[formula.ingredient.name].ranking) {
          results[formula.ingredient.name].count += 1;
          results[formula.ingredient.name].average =
            results[formula.ingredient.name].ranking /
            results[formula.ingredient.name].count;
        }
      } else {
        results[formula.ingredient.name] = {
          name: formula.ingredient.name,
          ranking: formula.ranking,
          average: formula.ranking ? formula.ranking : 9999,
          count: 1,
        };
      }
    });
  });

  const values = Object.values(results);

  values.sort((a, b) => {
    if (!isNumber(a.average) || !isNumber(b.average)) {
      return 1;
    }
    if (a.average < b.average) {
      return -1;
    }
    if (a.average > b.average) {
      return 1;
    }
    return 0;
  });

  return values;
};

export const getTotals = products => {
  const productTotals = products.reduce((productAcc, productKey) => {
    const totals = productKey.totals.reduce((acc, key) => {
      const _acc = acc;
      _acc[`${key.type}${key.depth}`] = {
        type: key.type,
        class_name: key.class_name,
        sort_order: key.sort_order,
        depth: key.depth,
        price_active: key.price_active,
      };
      return _acc;
    }, {});
    const _acc = Object.assign(productAcc, totals);
    return _acc;
  }, {});

  const formulations = Object.values(productTotals);
  const sorted = sortFormulations(formulations);

  return sorted;
};

/*
'name': 'Triblend Android T-Shirt',
'id': '12345',
'price': '15.25',
'brand': 'Google',
'category': 'Apparel',
  'variant': 'Gray',
  */

export const getProductImpressions = (items, list, position) => {
  const results = items.reduce((acc, item, i) => {
    const value = {
      id: `ProductID:${item.id}`,
      name: item.variant_name,
      category: item.category,
      brand: item.brand_name,

      dimension1: item.market,
      dimension2: item.subcategory,
      dimension3: item.format,
      dimension4: item.subformat,
      dimension5: item.brand_owner,
      dimension6: item.region,
      dimension7: item.country,
      dimension8: item.manufacturer,

      list,
      position: position || i + 1,
    };
    acc.push(value);
    return acc;
  }, []);

  return results;
};

export const getIngredientImpressions = (items, list, position) => {
  const results = items.reduce((acc, item, i) => {
    const value = {
      id: `IngredientID:${item.id}`,
      name: item.name,

      dimension9: item.type,
      dimension10: item.class_name,
      dimension11: item.format,
      dimension12: item.function,

      list,
      position: position || i + 1,
    };
    acc.push(value);
    return acc;
  }, []);

  return results;
};

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });

  return array;
};
