/**
 *
 * ProductsPage
 *
 */

import React from 'react';
import { Route, Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from '@material-ui/icons/FilterList';
import ReplayIcon from '@material-ui/icons/Replay';
import TagManager from 'react-gtm-module';

import { connect } from 'react-redux';
import { debounce } from 'lodash';
import Filter from '../Filter';
import history from '../../scripts/history';
import FilterChips from './chips';

import * as actionTypes from '../../store/actions';
import { getSearch } from '../../store/selectors';

// Utils
const styles = theme => ({
  filterContainer: {
    padding: theme.spacing(2),
    position: 'relative',
    width: 450,
    boxShadow: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  searchContainer: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
  },
  fieldset: {
    width: '100%',
  },
  nonCollapsableFormControl: {
    flex: 1,
  },
  formControl: {
    flex: '0 0 100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    border: '1px solid #A5D8DA',
    margin: '15px 15px 20px 15px',
    // boxShadow: '0 3px 5px 0 rgba(0,0,0,.15)',
  },
  inputText: {
    marginLeft: 8,
    flex: 1,
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
});

const Search = props => {
  const {
    classes,
    query,
    searchValue,
    store,
    display,
    onSetSearch,
    onClearFilter,
    filterPath,
  } = props;

  const handleCloseDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    history.goBack();
  };

  const searchEvent = debounce(s => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'search',
        category: 'search',
        search: s,
      },
    });
  }, 500);

  const handleSearch = event => {
    const s = event.target.value;
    onSetSearch(s);
    searchEvent(s);
  };

  const handleClear = () => {
    onClearFilter();
  };

  return (
    <>
      <Paper className={classes.searchContainer}>
        {query !== undefined && (
          <Route exact path={`${filterPath}`}>
            {({ match }) => {
              const isMatch = match && match.params;
              return (
                <Drawer
                  anchor="right"
                  open={!!isMatch}
                  onClose={e => handleCloseDrawer(e)}
                >
                  <Filter
                    store={store}
                    query={query}
                    display={display}
                    onClose={handleCloseDrawer}
                  />
                </Drawer>
              );
            }}
          </Route>
        )}
        <FormControl
          component="fieldset"
          className={classes.nonCollapsableFormControl}
        >
          <InputBase
            id="search"
            className={classes.inputText}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'Search' }}
            value={searchValue}
            onChange={e => handleSearch(e)}
          />
        </FormControl>
        <IconButton
          className={classes.iconButton}
          aria-label="Search"
          color="primary"
        >
          <SearchIcon />
        </IconButton>
        <IconButton
          onClick={() => handleClear()}
          color="primary"
          aria-label="Refresh"
        >
          <ReplayIcon />
        </IconButton>
        {query !== undefined && (
          <Link to={`${filterPath}`}>
            <IconButton color="primary" aria-label="Filter">
              <FilterIcon />
            </IconButton>
          </Link>
        )}
      </Paper>
      <FilterChips store={store} />
    </>
  );
};

Search.defaultProps = {
  filterPath: 'filter',
  searchValue: '',
  query: undefined,
};

Search.propTypes = {
  onSetSearch: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  query: PropTypes.object,
  display: PropTypes.object.isRequired,
  filterPath: PropTypes.string,
  store: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
};

const mapStateToProps = (state, props) => {
  return {
    searchValue: getSearch(state, props.store),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSetSearch: value =>
      dispatch({
        type: actionTypes.SET_FILTERSEARCH,
        store: props.store,
        search: value,
      }),
    onClearFilter: () =>
      dispatch({
        type: actionTypes.CLEAR_FILTER,
        store: props.store,
      }),
    onFilterToggle: (id, value) =>
      dispatch({
        type: actionTypes.TOGGLE_FILTERITEM,
        store: props.store,
        item: {
          id,
          value,
        },
      }),
    onRemoveFilter: value =>
      dispatch({
        type: actionTypes.REMOVE_FILTERITEM,
        store: props.store,
        item: {
          id: value,
        },
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Search));
