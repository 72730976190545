import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from '../../scripts/utils';

export const fixedValue = n => {
  let f = n;
  if (n > 10) f = Math.round(n);
  else if (n > 1) f = n.toFixed(1);
  return parseFloat(f);
};

function Range({ min, max, percentage = false }) {
  if (isNumber(min) && isNumber(max) && (min > 0 || max > 0))
    return (
      <>
        {fixedValue(min, 0)} - {fixedValue(max, 0)} {percentage ? '%' : null}
      </>
    );
  if (isNumber(max) && max >= 0)
    return (
      <>
        {'<'} {fixedValue(max, 0)} {percentage ? '%' : null}
      </>
    );
  if (isNumber(min) && min >= 0)
    return (
      <>
        {'>'} {fixedValue(min, 0)} {percentage ? '%' : null}
      </>
    );
  return null;
}

Range.defaultProps = {
  min: null,
  max: null,
  percentage: null,
};

Range.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  percentage: PropTypes.number,
};

export default Range;
