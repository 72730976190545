import React from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LinkIcon from '@material-ui/icons/AttachFile';

import { Button } from '@material-ui/core';

import Files from './index';

function SimpleDialog(props) {
  const { onClose, open, onChange } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="simple-dialog-title">Attach Files</DialogTitle>
      <DialogTitle disableTypography>
        Please upload a file and attach a file by click the paperclip
      </DialogTitle>

      <DialogContent>
        <Files onChange={onChange} />
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function SimpleDialogDemo({ onChange }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = value => {
    setOpen(false);
    onChange(value);
  };

  return (
    <div>
      <Button
        variant="contained"
        type="button"
        color="primary"
        aria-label="Compare"
        onClick={handleClickOpen}
        startIcon={<LinkIcon />}
      >
        Attach File
      </Button>

      <SimpleDialog open={open} onClose={handleClose} onChange={handleChange} />
    </div>
  );
}

SimpleDialogDemo.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SimpleDialogDemo;
