/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';

// import Yup from 'yup';
import * as Yup from 'yup';
import { graphql } from '@apollo/client/react/hoc';
import flowright from 'lodash.flowright';
import history from '../../scripts/history';
import DynamicForm from '../../components/DynamicForm';
import LoadingIndicator from '../../components/LoadingIndicator';

// Layout
import layout from '../../config/ingredient-layout.json';

import {
  CREATE_INGREDIENT,
  UPDATE_INGREDIENT,
  GET_INGREDIENT,
  DELETE_INGREDIENT,
} from '../../queries';

const display = layout.ingredient;

/*
const upsertSupplier = ({ price, variables }) => {
  const mutation = UPDATE_PRICE_SUPPLIER;
  let supplier = 'create';

  if (!price || price === '') {
    return Promise.reject(new Error('Error upsertSupplier price not defined'));
  }
  supplier = variables.id;

  const variable = { id: price, supplier };
  return client
    .mutate({
      mutation,
      variables: variable,
    })
    .then(result => result)
    .catch(e =>
      // GraphQL errors can be extracted here
      Promise.reject(e),
    );
};

const upsertPrices = ({ ingredient, variables }) => {
  // FETCH data using graphql
  if (!ingredient || ingredient === '') {
    return [];
  }

  if (!variables || variables.length === '') {
    return [];
  }
  return variables.map(_variable => {
    const variable = _variable || {};
    const mutation =
      !variable.id || variable.id === '' ? CREATE_PRICE : UPDATE_PRICE;
    variable.ingredient = ingredient;

    return client
      .mutate({
        mutation,
        variables: variable,
      })
      .then(result => {
        const price =
          !variable.id || variable.id === ''
            ? result.data.createPrice.price.id
            : result.data.updatePrice.price.id;

        return upsertSupplier({
          price,
          variables: variable.supplier,
        });
      })
      .catch(e =>
        // GraphQL errors can be extracted here
        Promise.reject(e),
      );
  });
};

const deletePrices = ({ variables }) => {
  // FETCH data using graphql
  const mutation = DELETE_PRICE;
  return variables.map(variable =>
    client
      .mutate({
        mutation,
        variables: variable,
      })
      .then(result => result)
      .catch(e =>
        // GraphQL errors can be extracted here
        Promise.reject(e),
      ),
  );
};
*/

function LoadingForm(props) {
  const {
    id,
    getIngredient,
    deleteIngredient,
    setSubmitting,
    setErrors,
  } = props;
  if (getIngredient.loading) {
    return <LoadingIndicator />;
  }
  const deleteValues = {
    id,
  };
  const handleDelete = () => {
    deleteIngredient({ variables: deleteValues })
      .then(() => {
        setSubmitting(false);
        history.push(`/products`);
      })
      .catch(e => {
        const errors = e.graphQLErrors || [];
        const error = errors.map(err => err.message).join('\n');
        setSubmitting(false);
        setErrors({ submitForm: error });
      });
  };

  return <DynamicForm handleDelete={id ? handleDelete : null} {...props} />;
}

LoadingForm.defaultProps = {
  id: null,
};

LoadingForm.propTypes = {
  getIngredient: PropTypes.object.isRequired,
  id: PropTypes.string,
  deleteIngredient: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
};

const FormikWithGraphQL = flowright(
  graphql(
    GET_INGREDIENT({
      with_price_active: true,
    }),
    {
      name: 'getIngredient',
      options: props => ({
        variables: {
          id: props.id,
        },
      }),
    },
  ),
  graphql(UPDATE_INGREDIENT, { name: 'updateIngredient' }),
  graphql(CREATE_INGREDIENT, { name: 'createIngredient' }),
  graphql(DELETE_INGREDIENT, { name: 'deleteIngredient' }),
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(128, 'No more then 128')
        .required('Ingredient name is required'),
    }),

    mapPropsToValues: props => {
      const ingredient =
        (props.getIngredient &&
          props.getIngredient.ingredient &&
          props.getIngredient.ingredient[0]) ||
        {};
      return ingredient;
    },
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { createIngredient, updateIngredient } = props;

      const isUpdate = !!(values.id && values.id !== '');
      const handleCreateOrUpdate = isUpdate
        ? updateIngredient
        : createIngredient;
      const updateValues = { ...values };
      delete updateValues.action;
      delete updateValues.formulas;
      delete updateValues.materials;
      delete updateValues.__typename;

      Object.keys(display.fields).forEach(name => {
        const input = display.fields[name];
        const { type } = input;
        if (type === 'float') {
          updateValues[name] = parseFloat(values[name]);
        }
      });

      handleCreateOrUpdate({ variables: updateValues })
        .then(data => {
          const ingredient = data.data.ingredient.ingredient[0];
          if (!ingredient || !ingredient.id || ingredient.id === '') {
            console.log(
              'Error updateIngredient ingredient not returned from graph',
              updateIngredient,
            );
          }
          history.push(`/ingredient/${ingredient.id}`);

          /*
          let promises = upsertPrices({
            variables: values.prices,
            ingredient: ingredient.id,
          });

          if (
            props.states.queryState.data &&
            props.states.queryState.data.ingredient &&
            props.states.queryState.data.ingredient.prices
          ) {
            const variables = props.states.queryState.data.ingredient.prices.filter(
              a => !values.prices.some(b => a.id === b.id),
            );

            promises = [...promises, ...deletePrices({ variables })];
          }

          Promise.all(promises)
            .then(() => {
              setSubmitting(false);
              history.push(`/ingredients/`);
            })
            .catch(e => {
              setSubmitting(false);
              ('Error handleSubmit', e);
            });
            */
        })
        .catch(e => {
          const errors = e.graphQLErrors || [];
          const error = errors.map(err => err.message).join('\n');
          setSubmitting(false);
          setErrors({ submitForm: error });
        });
    },
    displayName: 'Form',
  }),
)(LoadingForm);

FormikWithGraphQL.defaultProps = {};

FormikWithGraphQL.propTypes = {};

function IngredientForm(props) {
  const {
    match: {
      params: { id },
    },
  } = props;

  return <FormikWithGraphQL {...props} display={display} id={id} />;
}

IngredientForm.propTypes = {
  match: PropTypes.object.isRequired,
};

export default IngredientForm;
