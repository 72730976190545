import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useApolloClient } from '@apollo/client';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#000' : '#000',
    backgroundColor: state.isSelected ? 'rgba(165, 216, 218, 0.15)' : 'inherit',
    '&:hover': {
      // Overwrittes the different states of border
      backgroundColor: 'rgba(165, 216, 218, 0.25)',
    },
  }),
  menu: provided => ({
    ...provided,
    fontSize: 14,
  }),
  menuList: provided => ({
    ...provided,
    fontSize: 14,
  }),

  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: '#A5D8DA',
  }),
  clearIndicator: provided => ({
    ...provided,
    color: '#27827B',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#27827B',
  }),
  control: provided => ({
    ...provided,
    borderColor: '#A5D8DA',
    fontSize: 14,
    '&:hover': {
      // Overwrittes the different states of border
      borderColor: '#A5D8DA',
    },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

function GraphSelect(props) {
  const client = useApolloClient();

  const promiseOptions = inputValue =>
    client
      .query({
        query: props.query,
        variables: {
          name: `%${inputValue}%`,
        },
      })
      .then(result =>
        result.data.options.map(option => ({
          value: option.value,
          label: option.label,
        })),
      )
      .then(values =>
        values.filter(i =>
          i.label.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      )
      .catch(e => {
        // GraphQL errors can be extracted here
        if (e.graphQLErrors) {
          // reduce to get message
          console.log('Error', e, e.graphQLErrors);
        }
      });

  return (
    <AsyncSelect
      // onChange={handleChange}
      // onBlur={handleBlur}
      // value={props.value}
      cacheOptions
      defaultOptions
      loadOptions={inputValue => promiseOptions(inputValue)}
      isClearable
      styles={customStyles}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
}

GraphSelect.defaultProps = {
  value: {},
};

GraphSelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.object,
  query: PropTypes.object.isRequired,
};

export default GraphSelect;
