/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';

// import Yup from 'yup';
import * as Yup from 'yup';
import { graphql } from '@apollo/client/react/hoc';
import flowright from 'lodash.flowright';
import history from '../../scripts/history';
import DynamicForm from '../../components/DynamicForm';
import LoadingIndicator from '../../components/LoadingIndicator';

// Layout
import layout from '../../config/product-layout';
import {
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT,
  DELETE_PRODUCT,
} from '../../queries';

const display = layout.product;

function LoadingForm(props) {
  const { id, getProduct, deleteProduct, setSubmitting, setErrors } = props;
  if (getProduct.loading) {
    return <LoadingIndicator />;
  }
  const deleteValues = {
    id,
  };
  const handleDelete = () => {
    deleteProduct({ variables: deleteValues })
      .then(() => {
        setSubmitting(false);
        history.push(`/products`);
      })
      .catch(e => {
        const errors = e.graphQLErrors || [];
        const error = errors.map(err => err.message).join('\n');
        setSubmitting(false);
        setErrors({ submitForm: error });
      });
  };

  return (
    <DynamicForm
      handleDuplicate
      handleDelete={id ? handleDelete : null}
      {...props}
    />
  );
}

LoadingForm.defaultProps = {
  id: null,
};

LoadingForm.propTypes = {
  getProduct: PropTypes.object.isRequired,
  id: PropTypes.string,
  deleteProduct: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
};

const FormikWithGraphQL = flowright(
  graphql(
    GET_PRODUCT({
      with_price_active: true,
      with_percentage: true,
    }),
    {
      name: 'getProduct',
      options: props => ({
        variables: {
          ids: [props.id],
        },
      }),
    },
  ),
  graphql(UPDATE_PRODUCT, { name: 'updateProduct' }),
  graphql(CREATE_PRODUCT, { name: 'createProduct' }),
  graphql(DELETE_PRODUCT, { name: 'deleteProduct' }),
  withFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      variant_name: Yup.string()
        .max(128, 'No more then 128')
        .required('Variant name is required'),
      /*
      formulas: Yup.array()
        .of(
          Yup.object().shape({
            ranking: Yup.number()
              .required('Ranking is required')
              .typeError('Ranking must be a number'),
            ingredient: Yup.object()
              .typeError('Select ingredient')
              .shape({
                name: Yup.string()
                  .min(1, 'Ingredient is required')
                  .required('Ingredient is required')
                  .typeError('Select ingredient'),
              }),
          }),
        )
        .required('Must have formula') // these constraints are shown if and only if inner constraints are satisfied
        .min(1, 'Minimum of 1 formula'),
        */
    }),
    mapPropsToValues: props => {
      const { getProduct } = props;
      const product = (getProduct &&
        !getProduct.loading &&
        getProduct.product &&
        getProduct.product[0]) || { date_entered: new Date() };
      return product;
    },
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
      const { createProduct, updateProduct } = props;

      const isUpdate = !!(values.id && values.id !== '');
      const isDuplicate = !!(values.action === 'duplicate');
      const handleCreateOrUpdate =
        isUpdate && !isDuplicate ? updateProduct : createProduct;
      const updateValues = { ...values };
      delete updateValues.image;
      delete updateValues.action;
      delete updateValues.formulas_aggregate;
      delete updateValues.__typename;
      delete updateValues.totals;
      delete updateValues.created_at;
      delete updateValues.price_active;
      delete updateValues.ingredient_count;

      delete updateValues.cost_per_volume;
      delete updateValues.cost_per_weight;
      delete updateValues.cost_per_wash;
      delete updateValues.dosage_per_wash_weight;
      delete updateValues.dosage_per_wash_volume;

      delete updateValues.concentration_volume;
      delete updateValues.concentration_weight;

      if (values.file) {
        updateValues.file = values.file.id;
      } else {
        updateValues.file = null;
      }

      Object.keys(display.fields).forEach(name => {
        const input = display.fields[name];
        const { type } = input;
        if (type === 'float') {
          updateValues[name] = parseFloat(values[name]);
        }
      });
      if (isDuplicate) {
        const formulas = updateValues.formulas.map(f => {
          const formula = { ...f };
          formula.ingredient_id = formula.ingredient.id;
          delete formula.ingredient;
          delete formula.id;
          delete formula.product_id;
          delete formula.__typename;

          return formula;
        });
        delete updateValues.id;
        delete updateValues.formulation_id;
        updateValues.variant_name = `Copy of - ${updateValues.variant_name}`;
        updateValues.formulas = { data: formulas };
      } else {
        delete updateValues.formulas;
      }

      handleCreateOrUpdate({ variables: updateValues })
        .then(data => {
          const product = data.data.product.product[0];
          history.push(`/product/${product.id}`);
          /*
          let promises = upsertFormulas({
            variables: values.formulas,
            product: product.id,
          });

          if (
            props.states.queryState.data &&
            props.states.queryState.data.product &&
            props.states.queryState.data.product.formulas
          ) {
            const variables = props.states.queryState.data.product.formulas.filter(
              a => !values.formulas.some(b => a.id === b.id),
            );

            promises = [...promises, ...deleteFormulas({ variables })];
          }

          Promise.all(promises)
            .then(() => {
              setSubmitting(false);
              history.push(`/product/${product.id}`);
            })
            .catch(e => {
              setSubmitting(false);
            });
          //
          */
          /*
        resetForm({
          id: updateProduct.id,
          name: updateProduct.name,
        });
        */
        })
        .catch(e => {
          const errors = e.graphQLErrors || [];
          const error = errors.map(err => err.message).join('\n');
          setSubmitting(false);
          setErrors({ submitForm: error });
        });
    },
    displayName: 'Form',
  }),
)(LoadingForm);

FormikWithGraphQL.defaultProps = {};

function ProductForm(props) {
  const {
    match: {
      params: { id },
    },
  } = props;

  return <FormikWithGraphQL {...props} display={display} id={id} />;
}

ProductForm.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ProductForm;
