import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import DateFnsUtils from '@date-io/date-fns';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import TagManager from 'react-gtm-module';

import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH_CONFIG } from './config/constants';

import history from './scripts/history';
// Import root app
import App from './containers/App';
import reducer from './store/reducer';

import 'sanitize.css/sanitize.css';
import './styles/main.css';


const store = createStore(
   reducer, /* preloadedState, */
   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
 );

const MOUNT_NODE = document.getElementById('root');
const tagManagerArgs = {
  gtmId: 'GTM-MPXW3HT',
};
TagManager.initialize(tagManagerArgs);

const onRedirectCallback = appState => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

const render = () => {
  ReactDOM.render(
    <Auth0Provider
      domain={AUTH_CONFIG.domain}
      clientId={AUTH_CONFIG.clientId}
      audience={AUTH_CONFIG.audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router history={history}>
          <Provider store={store}>
            <App />
          </Provider>
        </Router>
      </MuiPickersUtilsProvider>
    </Auth0Provider>,
    MOUNT_NODE,
  );
};

render();

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}
