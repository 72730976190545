import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';
import FieldList from '../FieldList';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  button: {
    margin: '5px',
  },
  buttons: {
    margin: -5,
  },
  error: {
    color: 'red',
    margin: '10px 0',
    fontSize: 16,
  },
});

const ColorButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
})(Button);

const DynamicForm = props => {
  const {
    errors,
    handleSubmit,
    submitForm,
    isSubmitting,
    isLoading,
    display,
    handleDelete,
    handleDuplicate,
    setFieldValue,
  } = props;
  const { classes, ...otherProps } = props;

  const canDelete = true;

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {isSubmitting && !isLoading && <p>Saving...</p>}
      {!isSubmitting && !isLoading && (
        <Paper className={classes.container} elevation={4}>
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FieldList {...otherProps} display={display} baseName="" />
            {errors && errors.submitForm ? (
              <div className={classes.error}>{errors.submitForm}</div>
            ) : null}
            <div className={classes.buttons}>
              <Button
                variant="contained"
                type="button"
                color="primary"
                className={classes.button}
                onClick={async () => {
                  await setFieldValue('action', 'upsert');
                  submitForm();
                }}
              >
                Save
              </Button>
              {handleDuplicate && (
                <Button
                  variant="contained"
                  type="button"
                  color="secondary"
                  className={classes.button}
                  onClick={async () => {
                    await setFieldValue('action', 'duplicate');
                    submitForm();
                  }}
                >
                  Duplicate
                </Button>
              )}
              {handleDelete && canDelete && (
                <ColorButton
                  variant="contained"
                  type="button"
                  color="primary"
                  className={classes.button}
                  onClick={async () => {
                    await setFieldValue('action', 'delete');
                    handleDelete();
                  }}
                >
                  Delete
                </ColorButton>
              )}
            </div>
          </form>
        </Paper>
      )}
    </>
  );
};

DynamicForm.defaultProps = {
  isLoading: false,
  values: [],
  handleDelete: null,
  handleDuplicate: false,
};

DynamicForm.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  display: PropTypes.object.isRequired,
  values: PropTypes.object,
  handleDelete: PropTypes.func,
  handleDuplicate: PropTypes.bool,
};

export default withStyles(styles)(DynamicForm);
