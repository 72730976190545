import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Price from '../Price';
import Can from '../Can';

const styles = () => ({
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
  },
  detailsTable: {
    marginBottom: 50,
    '& td, & th': {
      borderColor: '#A5D8DA',
    },
  },

  tableHead: {
    whiteSpace: 'nowrap',
  },

  headCell: {
    whiteSpace: 'nowrap',
  },

  actionCell: {
    width: 50,
    paddingTop: 0,
    paddingBottom: 0,
  },

  valueCell: {
    minWidth: 200,
  },

  editButton: {
    top: '0',
    right: '0',
  },

  linkIcon: {
    display: 'flex',
    alignItems: 'center',
  },
});

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(165, 216, 218, 0.15)',
    },
  },
}))(TableRow);

const StyledPrice = withStyles(() => ({
  textAlign: 'right',
}))(Price);

function ProductMaterials(props) {
  const { classes, products } = props;

  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.detailsTable} size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.valueCell}>Tradename</TableCell>
            <TableCell className={classes.headCell}>Ingredient</TableCell>
            <TableCell className={classes.headCell}>Suppliername</TableCell>
            <TableCell className={classes.headCell}>Description</TableCell>
            <TableCell className={classes.headCell}>Price (raw)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(product =>
            product.formulas.map(formula =>
              formula.ingredient.materials.map(material => {
                return (
                  <StyledTableRow key={material.id}>
                    <TableCell component="th" scope="row">
                      <span className={classes.linkIcon}>
                        <Can
                          perform="material:edit"
                          yes={() => (
                            <Link
                              className={classes.editButton}
                              to={`/material/${material.id}`}
                            >
                              <IconButton
                                size="small"
                                aria-label="Raw Material Details"
                                color="primary"
                              >
                                <InfoIcon />
                              </IconButton>
                            </Link>
                          )}
                        />
                        <span> {material.trade_name}</span>
                      </span>
                    </TableCell>
                    <TableCell className={classes.headCell}>
                      {material.ingredient.name}
                    </TableCell>
                    <TableCell className={classes.headCell}>
                      {material.supplier.name}
                    </TableCell>
                    <TableCell className={classes.headCell}>
                      {material.description}
                    </TableCell>
                    <TableCell className={classes.headCell}>
                      <StyledPrice value={material.price_raw} />
                    </TableCell>
                  </StyledTableRow>
                );
              }),
            ),
          )}
        </TableBody>
      </Table>
    </div>
  );
}

ProductMaterials.defaultProps = {};

ProductMaterials.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProductMaterials);
