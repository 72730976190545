/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * ProductsPage
 *
 */

import React from 'react';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Search from '../../components/Search';
import Listing from './listing';
import Favorites from './favorites';

import { GET_PRODUCT_COUNTS } from '../../queries';

import layout from '../../config/product-layout';
import Can from '../../components/Can';

const display = layout.product;

// Utils
const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  fabButton: {
    margin: '0 auto',
  },
  buttons: {
    margin: '20px -5px',
  },
  buttonsCentered: {
    margin: '20px -5px',
    textAlign: 'center',
  },
  button: {
    margin: 5,
  },
});

const ProductsPage = props => {
  const { classes } = props;

  return (
    <>
      <Helmet>
        <title>Products Overview Page</title>
        <meta name="description" content="Products Overview Page" />
      </Helmet>

      <Paper className={classes.container} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>Products</h3>
            <Search
              store="products"
              query={GET_PRODUCT_COUNTS}
              display={display}
              filterPath="/products/filter"
            />
            <Listing />
          </Grid>
        </Grid>

        <div className={classes.buttonsCentered}>
          <Can
            perform="product:add"
            yes={() => (
              <Fab
                component={Link}
                color="primary"
                className={classes.fabButton}
                to="/update/product/"
                aria-label="Add product"
              >
                <AddIcon />
              </Fab>
            )}
          />
        </div>

        <Favorites />
      </Paper>
    </>
  );
};

ProductsPage.defaultProps = {};

ProductsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductsPage);
