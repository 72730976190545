import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: red;
  white-space: pre;
`;

const AccesDenied = () => (
  <Wrapper>
    <h3>It looks like you dont have access to this page.</h3>
  </Wrapper>
);

AccesDenied.defaultProps = {};

AccesDenied.propTypes = {};

export default AccesDenied;
