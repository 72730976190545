import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

function MySelect({ id, onChange, onBlur, value, options }) {
  const handleChange = _value => {
    // this is going to call setFieldValue and manually update
    onChange(id, _value ? _value.value : null);
    // props.setFieldValue(props.name, option)}
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    onBlur(id, true);
  };

  return (
    <Select
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      options={options}
      isClearable
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

MySelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default MySelect;
